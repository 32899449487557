import { Modal } from "antd";

function BaseLessonTypeModal({
    open,
    setOpen,
    title,
    className = "",
    children,
    ...props
}) {
    return (
        <Modal
            className={"lesson_type_modal " + className}
            width={"min(55rem, 95%)"}
            title={null}
            footer={null}
            closable={false}
            maskClosable={false}
            centered
            open={open}
            zIndex={998}
            onCancel={() => setOpen(false)}
            {...props}
        >
            <div className={"lesson_type_modal__wrapper"}>
                <p className={"lesson_type_modal__heading"}>{title}</p>
                {children}
            </div>
        </Modal>
    );
}

export default BaseLessonTypeModal;
