import { Button, Modal, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import SvgIconReload from "assets/icons/IconReload";
import Icon from "@ant-design/icons";
import { CUSTOM_BLOCK_TYPES } from "helpers/questionHelpers";

const contextSuggestions = {
    Name: {
        keepCase: true,
        options: [
            "Alice",
            "Ben",
            "Charlie",
            "Daisy",
            "Emily",
            "Frank",
            "Grace",
            "Hannah",
            "Ibrahim",
            "Jack",
            "Liam",
            "Olivia",
            "Ethan",
            "Sophia",
            "Mia",
            "Oscar",
            "Leo",
            "Charlotte",
            "Isabella",
            "Lucas"
        ]
    },
    "Object (small)": {
        keepCase: false,
        options: [
            "Pencil",
            "Eraser",
            "Coin",
            "Paperclip",
            "Button",
            "Dice",
            "Key",
            "Battery",
            "Bead",
            "Lego brick"
        ]
    },
    Sport: {
        keepCase: false,
        options: [
            "Football",
            "Tennis",
            "Cricket",
            "Basketball",
            "Swimming",
            "Rugby",
            "Hockey",
            "Badminton",
            "Golf",
            "Cycling",
            "Archery"
        ]
    },
    Country: {
        keepCase: true,
        options: [
            "United Kingdom",
            "France",
            "Germany",
            "Spain",
            "Italy",
            "Australia",
            "India",
            "Canada",
            "Brazil",
            "Japan"
        ]
    },
    Colour: {
        keepCase: false,
        options: [
            "Red",
            "Blue",
            "Green",
            "Yellow",
            "Purple",
            "Orange",
            "Black",
            "White",
            "Pink",
            "Brown"
        ]
    },
    Animal: {
        keepCase: false,
        options: [
            "Dog",
            "Cat",
            "Elephant",
            "Giraffe",
            "Kangaroo",
            "Lion",
            "Panda",
            "Dolphin",
            "Penguin",
            "Zebra"
        ]
    },
    "Object (large)": {
        keepCase: false,
        options: [
            "Car",
            "Bus",
            "House",
            "Tree",
            "Boat",
            "Train",
            "Plane",
            "Bridge",
            "Skyscraper",
            "Truck"
        ]
    },
    City: {
        keepCase: true,
        options: [
            "London",
            "Paris",
            "New York",
            "Tokyo",
            "Berlin",
            "Sydney",
            "Mumbai",
            "Rome",
            "Toronto",
            "Dubai",
            "Madrid",
            "Buenos Aires",
            "Seoul",
            "Cape Town",
            "Vienna",
            "Istanbul",
            "San Francisco",
            "Cairo",
            "Amsterdam",
            "Beijing"
        ]
    },
    Food: {
        keepCase: false,
        options: [
            "Pizza",
            "Burger",
            "Pasta",
            "Apple",
            "Orange",
            "Sandwich",
            "Salad",
            "Chocolate",
            "Soup",
            "Ice cream",
            "Sushi",
            "Curry",
            "Taco",
            "Pancake",
            "Burrito",
            "Muffin",
            "Steak",
            "Smoothie",
            "Pie"
        ]
    },
    Hobby: {
        keepCase: false,
        options: [
            "Reading",
            "Painting",
            "Cycling",
            "Gardening",
            "Swimming",
            "Cooking",
            "Photography",
            "Dancing",
            "Running",
            "Drawing"
        ]
    },
    Job: {
        keepCase: false,
        options: [
            "Doctor",
            "Teacher",
            "Engineer",
            "Nurse",
            "Pilot",
            "Firefighter",
            "Police Officer",
            "Chef",
            "Dentist",
            "Mechanic"
        ]
    },
    "Clothing Item": {
        keepCase: false,
        options: [
            "T-shirt",
            "Jeans",
            "Jacket",
            "Hat",
            "Dress",
            "Shoes",
            "Socks",
            "Scarf",
            "Gloves",
            "Skirt"
        ]
    },
    Gemstone: {
        keepCase: false,
        options: [
            "Diamond",
            "Ruby",
            "Emerald",
            "Sapphire",
            "Amethyst",
            "Topaz",
            "Opal",
            "Garnet",
            "Aquamarine"
        ]
    },
    "TV Show": {
        keepCase: true,
        options: [
            "Blue Planet",
            "The Simpsons",
            "Friends",
            "Stranger Things",
            "The Great British Bake Off",
            "Planet Earth",
            "Peppa Pig",
            "The Crown",
            "SpongeBob SquarePants",
            "Sherlock",
            "Doctor Who"
        ]
    },
    Movie: {
        keepCase: true,
        options: [
            "Toy Story",
            "Frozen",
            "The Lion King",
            "Star Wars",
            "Finding Nemo",
            "Jurassic Park",
            "The Avengers",
            "Coco",
            "Spider-Man",
            "Incredibles",
            "Moana",
            "Aladdin",
            "Despicable Me",
            "Up",
            "WALL-E",
            "Monsters, Inc.",
            "Zootopia",
            "Beauty and the Beast"
        ]
    }
};

function SuggestionSection({
    title,
    subtitle = null,
    options,
    onSelect = (s) => {}
}) {
    return (
        <div className={"suggestion_keyboard__section"}>
            <div className={"suggestion_keyboard__section__title"}>
                <h1>{title}</h1>
                {subtitle && <h2>{subtitle}</h2>}
            </div>
            <div className={"suggestion_keyboard__section__content"}>
                {options.map((o) => (
                    <Button
                        onMouseDown={(e) => {
                            onSelect(o);
                            e.preventDefault();
                        }}
                    >
                        {o}
                    </Button>
                ))}
            </div>
        </div>
    );
}

function RandomSelectorModal({ open, onClose, onInsert, category = null }) {
    const [chosen, setChosen] = useState(null);

    const selectNewChosen = useCallback((category, existingChosen = null) => {
        if (!category) {
            setChosen(null);
            return;
        }
        if (!contextSuggestions?.[category]?.options?.length) {
            setChosen(null);
            return;
        }
        const options = contextSuggestions[category]?.options.filter(
            (o) => o !== existingChosen
        );
        if (options.length) {
            setChosen(options[Math.floor(Math.random() * options.length)]);
        }
    }, []);

    useEffect(() => {
        if (!open) {
            setChosen(null);
        }
        if (!chosen) {
            selectNewChosen(category);
        }
    }, [category, chosen, open, selectNewChosen]);

    return (
        <Modal
            title={null}
            footer={null}
            closable={false}
            width={"fit-content"}
            style={{ maxWidth: "95%" }}
            destroyOnClose={true}
            open={open}
            onCancel={onClose}
            centered
            className={"random-context-modal " + TEXT_KEYBOARD_MODAL_CLASS}
            autoFocusButton={false}
            focusTriggerAfterClose={true /* Returns focus to text input */}
        >
            <Space direction={"vertical"} size={"large"}>
                <h1>Random {category}</h1>
                <Button
                    className={"random-context-modal__switcher"}
                    icon={<Icon component={() => <SvgIconReload />} />}
                    onClick={selectNewChosen}
                >
                    {chosen}
                </Button>
                <Space direction={"horizontal"}>
                    <Button
                        type={"primary"}
                        onClick={() => {
                            onInsert(
                                chosen,
                                contextSuggestions[category]?.keepCase ?? false
                            );
                            onClose();
                        }}
                    >
                        Insert
                    </Button>
                    <Button
                        type={"text"}
                        onClick={onClose}
                        className={"cancel"}
                    >
                        Cancel
                    </Button>
                </Space>
            </Space>
        </Modal>
    );
}

export const TEXT_KEYBOARD_ID = "textKbd";
export const TEXT_KEYBOARD_MODAL_CLASS = "textKbdModal";

function SuggestionKeyboard({ className = undefined, replaceActiveBlock }) {
    const [activeContext, setActiveContext] = useState(null);

    const insertText = useCallback((text, keepCase = false) => {
        const sel = window.getSelection();
        if (!sel) {
            return;
        }
        if (!sel.rangeCount) {
            return;
        }
        const range = sel.getRangeAt(0);
        let currNode =
            sel.anchorOffset <= sel.focusOffset
                ? sel.anchorNode
                : sel.focusNode;
        const isChild = !currNode?.classList;
        const parent = currNode.parentNode;
        let prevText;
        if (isChild) {
            // Iterate backwards through previous text nodes to get full length
            let selectionStartIndex = Math.min(
                sel?.anchorOffset ?? 0,
                sel?.focusOffset ?? 0
            ); // min() is in case of backwards selection
            while (
                currNode.previousSibling?.textContent &&
                currNode.previousSibling.parentNode === parent
            ) {
                currNode = currNode.previousSibling;
                selectionStartIndex += currNode.textContent.length + 1;
            }
            prevText = (document.activeElement?.textContent ?? "").slice(
                0,
                selectionStartIndex
            );
        } else {
            /* Is entire text-field => selection is at end */
            prevText = document.activeElement?.textContent ?? "";
        }
        prevText = prevText.trim();
        range.deleteContents();
        const atStartOfBlock = !prevText?.length;
        const atEndOfSentence = [".", "!", "?"].includes(
            prevText.charAt(prevText.length - 1)
        );
        range.insertNode(
            document.createTextNode(
                (!atStartOfBlock && !atEndOfSentence && !keepCase
                    ? text.toLowerCase()
                    : text) + " "
            )
        );
        sel.collapseToEnd();
    }, []);

    const insertEquation = useCallback(() => {
        const target = document.activeElement;
        const value = target?.textContent;
        const sel = window.getSelection();
        const pos = sel.getRangeAt(0).startOffset;
        const before = (value?.slice?.(0, pos) ?? "").trim();
        const after = (value?.slice?.(pos) ?? "").trim();
        replaceActiveBlock(
            [
                Boolean(before) && {
                    type: CUSTOM_BLOCK_TYPES.TEXT,
                    val: before
                },
                { type: CUSTOM_BLOCK_TYPES.MATH, val: null },
                Boolean(after) && { type: CUSTOM_BLOCK_TYPES.TEXT, val: after }
            ].filter((b) => b),
            Boolean(before)
        );
    }, [replaceActiveBlock]);

    return (
        <div
            id={TEXT_KEYBOARD_ID}
            className={"suggestion_keyboard " + className}
            tabIndex={-1}
            onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div className={"suggestion_keyboard__header"}>
                <p>Use your keyboard to type text or use our handy pre-sets</p>
                <Button type={"text"} onClick={insertEquation}>
                    Insert Equation
                </Button>
            </div>
            <div className={"suggestion_keyboard__content"}>
                <SuggestionSection
                    title={"Open"}
                    options={[
                        "Work out",
                        "Simplify",
                        "Expand",
                        "Calculate",
                        "Find",
                        "Simplify the following",
                        "Compare",
                        "Evaluate",
                        "Expand and simplify",
                        "Describe"
                    ]}
                    onSelect={(selected) => insertText(selected)}
                />
                <RandomSelectorModal
                    open={Boolean(activeContext)}
                    onClose={() => setActiveContext(null)}
                    onInsert={insertText}
                    category={activeContext}
                />
                <SuggestionSection
                    title={"Context"}
                    subtitle={"generate a random"}
                    options={Object.keys(contextSuggestions)}
                    onSelect={(selected) => setActiveContext(selected)}
                />
                <SuggestionSection
                    title={"Finish"}
                    options={[
                        "Show full workings",
                        "Round your answer to 1 d.p.",
                        "Round your answer to 2 d.p.",
                        "Round your answer to 1 s.f.",
                        "Round your answer to 2 s.f.",
                        "Give your answer in its simplest form"
                    ]}
                    onSelect={(selected) => insertText(selected)}
                />
            </div>
        </div>
    );
}

export default SuggestionKeyboard;
