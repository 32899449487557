import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState
} from "react";
import { Badge, Tooltip } from "antd";
import { CloseCircleFilled, EnterOutlined } from "@ant-design/icons";
import { tooltipColour } from "helpers/starterHelpers";
import EmptyCircle from "assets/icons/EmptyCircle";
import "mathlive";
import {
    TEXT_KEYBOARD_ID,
    TEXT_KEYBOARD_MODAL_CLASS
} from "./SuggestionKeyboard";

export const MathInput = forwardRef(
    (
        {
            value,
            onChange,
            onBackspace,
            onDelete,
            onNewLine,
            onBlur,
            onFocus,
            onTab
        },
        ref
    ) => {
        const unwrappedRef = useRef(null);
        useImperativeHandle(ref, () => unwrappedRef.current, [unwrappedRef]);
        const shiftOn = useRef(false);

        useEffect(() => {
            const mf = unwrappedRef?.current;
            if (!mf || !window?.mathVirtualKeyboard) {
                return;
            }
            mf.mathVirtualKeyboardPolicy = "auto";
            const show = () => window.mathVirtualKeyboard.show();
            const hide = () => window.mathVirtualKeyboard.hide();
            mf.addEventListener("focusin", show);
            mf.addEventListener("focusout", hide);
            const handleNewline = (e) => {
                if ((e?.inputType ?? e?.data) === "insertLineBreak") {
                    // const cursor = "¬¬";
                    // const cursorIndicator = `\\placeholder[${cursor}]{}`;
                    // e.target.executeCommand(["insert", cursorIndicator]);
                    // const value = e.target.value;
                    // e.target.executeCommand(["undo"]);
                    // const [before, after] = value.split(cursorIndicator);
                    // if (after.trim()) {
                    //     onNewLine(before, after);
                    // } else {
                    e.target.blur();
                    onBlur();
                    // }
                    e.preventDefault();
                }
            };
            mf.addEventListener("beforeinput", handleNewline);

            return () => {
                mf.removeEventListener("focusin", show);
                mf.removeEventListener("focusout", hide);
                mf.removeEventListener("beforeinput", handleNewline);
            };
        }, [onBlur, onNewLine]);

        return (
            <math-field
                ref={unwrappedRef}
                onInput={(e) => {
                    onChange(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.key.includes("Shift")) {
                        shiftOn.current = true;
                    } else if (e.key === "Backspace" && !value?.trim?.()) {
                        onBackspace();
                    } else if (e.key === "Delete" && !value?.trim?.()) {
                        onDelete();
                    } else if (e.key === "Tab" && !shiftOn.current) {
                        onTab();
                    }
                }}
                onKeyUp={(e) => {
                    if (e.key.includes("Shift")) {
                        shiftOn.current = false;
                    }
                }}
                smartMode={true}
                onBlur={onBlur}
                onFocus={onFocus}
            >
                {value}
            </math-field>
        );
    }
);

export const TextInput = forwardRef(
    (
        {
            value,
            onChange,
            onBackspace,
            onDelete,
            onNewLine,
            onBlur,
            onFocus,
            onTab
        },
        ref
    ) => {
        const shiftOn = useRef(false);

        const onBlurLocal = useCallback(
            (e) => {
                const textKeyboard = document.getElementById(TEXT_KEYBOARD_ID);
                if (
                    !e?.relatedTarget ||
                    (!textKeyboard?.contains?.(e?.relatedTarget) &&
                        !(
                            e?.relatedTarget?.parentElement?.classList ?? []
                        ).contains(TEXT_KEYBOARD_MODAL_CLASS))
                ) {
                    onChange(e.target.textContent);
                    onBlur();
                } else {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            [onBlur, onChange]
        );

        return (
            <span
                ref={ref}
                data-gramm="false"
                data-gramm_editor="false"
                data-enable-grammarly="false"
                contentEditable
                className={"text-field"}
                onBlur={onBlurLocal}
                onFocus={onFocus}
                onKeyDown={(e) => {
                    // Uses textContent here as value is only updated when input focus is lost
                    // Here, textContent yields the value prior to the keyUp event (before the addition/deletion)
                    const value = e.target?.textContent;
                    if (e.key.includes("Shift")) {
                        shiftOn.current = true;
                    } else if (e.key === "Backspace" && !value?.trim?.()) {
                        onBackspace();
                    } else if (e.key === "Delete" && !value?.trim?.()) {
                        onDelete();
                    } else if (e.key === "Tab" && !shiftOn.current) {
                        onTab();
                    } else if (e.key === "Enter") {
                        const pos = window
                            .getSelection()
                            .getRangeAt(0).startOffset;
                        const after = (value?.slice?.(pos) ?? "").trim();
                        if (after && shiftOn.current) {
                            onNewLine(
                                (value?.slice?.(0, pos) ?? "").trim(),
                                after
                            );
                        } else {
                            e.target?.blur?.();
                            onBlurLocal(e);
                        }
                        e.preventDefault();
                    }
                }}
                onKeyUp={(e) => {
                    if (e.key.includes("Shift")) {
                        shiftOn.current = false;
                    }
                }}
            >
                {value}
            </span>
        );
    }
);

export function InsertControl({ type, typeDescription = undefined, onClick }) {
    return (
        <Tooltip
            title={`Insert ${typeDescription ?? type}`}
            color={tooltipColour}
        >
            <span className={"insert-control " + type} onClick={onClick}>
                <EmptyCircle />
            </span>
        </Tooltip>
    );
}

export function NewLineControl({ onDelete }) {
    const [hover, setHover] = useState(false);
    return (
        <Tooltip title={`Remove New Line`} color={tooltipColour}>
            <span
                className="new-line-field__wrapper"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                onClick={onDelete}
            >
                <Badge
                    color={"#595959"}
                    offset={[-3, 6]}
                    style={{ cursor: "pointer", background: "white" }}
                    count={hover ? <CloseCircleFilled /> : 0}
                    size="small"
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                >
                    <span className="new-line-field">
                        <EnterOutlined />
                    </span>
                </Badge>
            </span>
        </Tooltip>
    );
}
