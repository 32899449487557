import { CUSTOM_BLOCK_TYPES } from "helpers/questionHelpers";
import React, { useEffect, useRef } from "react";
import {
    InsertControl,
    MathInput,
    NewLineControl,
    TextInput
} from "./InputControls";
import { mergeSameTypeBlocks } from "helpers/customQuestionHelpers";

function CustomQuestionTextArea({
    values,
    setValues,
    focusedBlock = null,
    setFocusedBlock = (i) => {}
}) {
    const blockRefs = useRef([]);

    useEffect(() => {
        blockRefs.current = blockRefs.current.slice(0, values.length);
    }, [values]);

    useEffect(() => {
        if (focusedBlock === null) {
            return;
        }
        if (blockRefs?.current?.[focusedBlock]) {
            blockRefs.current[focusedBlock]?.focus?.({ focusVisible: false });
        } else {
            setFocusedBlock(focusedBlock > 0 ? focusedBlock - 1 : null);
        }
    }, [focusedBlock, setFocusedBlock, values]);

    const controls = values.flatMap((value, i) => {
        const nextType = values.at(i + 1)?.type;
        const previousType = values.at(i - 1)?.type;

        const handleChange = (newVal) =>
            setValues((prev) =>
                [...prev].toSpliced(i, 1, {
                    ...prev[i],
                    val: newVal
                })
            );

        const insertMidBlockNewline = (before = "", after = "") => {
            setValues((prev) => [
                ...prev.slice(0, i),
                ...(before ? [{ ...prev[i], val: before }] : []),
                { type: CUSTOM_BLOCK_TYPES.NEW_LINE },
                ...(after ? [{ ...prev[i], val: after }] : []),
                ...prev.slice(i + 1)
            ]);
        };

        const deleteForwards = () => {
            setValues((prev) =>
                mergeSameTypeBlocks(
                    nextType !== CUSTOM_BLOCK_TYPES.NEW_LINE
                        ? [...prev].toSpliced(i, 1)
                        : [...prev].toSpliced(i, 2)
                )
            );
            setFocusedBlock(i);
        };

        const deleteBackwards = () => {
            setValues((prev) =>
                mergeSameTypeBlocks(
                    previousType !== CUSTOM_BLOCK_TYPES.NEW_LINE
                        ? [...prev].toSpliced(i, 1)
                        : [...prev].toSpliced(i - 1, 2)
                )
            );
            setFocusedBlock(i - 1);
        };

        const handleTab = () => {
            if (!nextType) {
                setValues((prev) => [
                    ...prev,
                    {
                        val: "",
                        type:
                            prev.at(-1)?.type === CUSTOM_BLOCK_TYPES.MATH
                                ? CUSTOM_BLOCK_TYPES.TEXT
                                : CUSTOM_BLOCK_TYPES.MATH
                    }
                ]);
                setFocusedBlock(values.length);
            }
        };

        switch (value.type) {
            case CUSTOM_BLOCK_TYPES.MATH:
                return (
                    <MathInput
                        key={i}
                        ref={(el) => {
                            if (el) {
                                blockRefs.current[i] = el;
                            } else {
                                delete blockRefs.current[i];
                            }
                        }}
                        value={value.val}
                        onChange={handleChange}
                        onNewLine={insertMidBlockNewline}
                        onBackspace={deleteBackwards}
                        onDelete={deleteForwards}
                        onFocus={() => setFocusedBlock(i)}
                        onBlur={() => setFocusedBlock(null)}
                        onTab={handleTab}
                    />
                );
            case CUSTOM_BLOCK_TYPES.TEXT:
                return (
                    <TextInput
                        key={i}
                        ref={(el) => {
                            if (el) {
                                blockRefs.current[i] = el;
                            } else {
                                delete blockRefs.current[i];
                            }
                        }}
                        value={value.val}
                        onChange={handleChange}
                        onNewLine={insertMidBlockNewline}
                        onBackspace={deleteBackwards}
                        onDelete={deleteForwards}
                        onFocus={() => setFocusedBlock(i)}
                        onBlur={() => setFocusedBlock(null)}
                        onTab={handleTab}
                    />
                );
            case CUSTOM_BLOCK_TYPES.NEW_LINE:
                return [
                    <InsertControl
                        type={
                            previousType === CUSTOM_BLOCK_TYPES.MATH
                                ? "Text"
                                : "Equation"
                        }
                        onClick={() => {
                            setValues((prev) => [
                                ...prev.slice(0, i),
                                {
                                    val: "",
                                    type:
                                        previousType === CUSTOM_BLOCK_TYPES.MATH
                                            ? CUSTOM_BLOCK_TYPES.TEXT
                                            : CUSTOM_BLOCK_TYPES.MATH
                                },
                                ...prev.slice(i)
                            ]);
                            setFocusedBlock(i);
                        }}
                    />,
                    <InsertControl
                        type={"NewLine"}
                        typeDescription={"New Line"}
                        onClick={() => {
                            setValues((prev) => [
                                ...prev.slice(0, i),
                                {
                                    val: "",
                                    type: CUSTOM_BLOCK_TYPES.NEW_LINE
                                },
                                ...prev.slice(i)
                            ]);
                            setFocusedBlock(null);
                        }}
                    />,
                    <NewLineControl
                        onDelete={() => {
                            setValues((prev) =>
                                mergeSameTypeBlocks([...prev].toSpliced(i, 1))
                            );
                            setFocusedBlock(i);
                        }}
                    />,
                    <br />,
                    <InsertControl
                        type={
                            nextType !== CUSTOM_BLOCK_TYPES.TEXT
                                ? "Text"
                                : "Equation"
                        }
                        onClick={() => {
                            setValues((prev) => [
                                ...prev.slice(0, i + 1),
                                {
                                    val: "",
                                    type:
                                        nextType !== CUSTOM_BLOCK_TYPES.TEXT
                                            ? CUSTOM_BLOCK_TYPES.TEXT
                                            : CUSTOM_BLOCK_TYPES.MATH
                                },
                                ...prev.slice(i + 1)
                            ]);
                            setFocusedBlock(i + 1);
                        }}
                    />
                ];
            default:
                return [];
        }
    });

    const firstNewLineBlock = values.filter(
        ({ type }) => type === CUSTOM_BLOCK_TYPES.NEW_LINE
    )?.[0];
    const firstLine = values.slice(
        0,
        firstNewLineBlock ? values.indexOf(firstNewLineBlock) : values.length
    );
    const isFirstMath =
        values.at(0)?.type === CUSTOM_BLOCK_TYPES.MATH ||
        firstLine.length === 0;
    const isLastMath = values.at(-1)?.type === CUSTOM_BLOCK_TYPES.MATH;

    return (
        <div className="custom-question-modal__card__question__wrapper">
            <InsertControl
                type={isFirstMath ? "Text" : "Equation"}
                onClick={() => {
                    setValues((prev) => [
                        {
                            val: "",
                            type: isFirstMath
                                ? CUSTOM_BLOCK_TYPES.TEXT
                                : CUSTOM_BLOCK_TYPES.MATH
                        },
                        ...prev
                    ]);
                    setFocusedBlock(0);
                }}
            />
            {controls}
            <InsertControl
                type={isLastMath ? "Text" : "Equation"}
                onClick={() => {
                    setValues((prev) => [
                        ...prev,
                        {
                            val: "",
                            type: isLastMath
                                ? CUSTOM_BLOCK_TYPES.TEXT
                                : CUSTOM_BLOCK_TYPES.MATH
                        }
                    ]);
                    setFocusedBlock(values.length);
                }}
            />
            <InsertControl
                key={
                    "nl-end-" +
                    values.length /* Prevents tooltip 'travelling' to new line before disappearing */
                }
                type={"NewLine"}
                typeDescription={"New Line"}
                onClick={() => {
                    setValues((prev) => [
                        ...prev,
                        {
                            val: "",
                            type: CUSTOM_BLOCK_TYPES.NEW_LINE
                        }
                    ]);
                    setFocusedBlock(null);
                }}
            />
        </div>
    );
}

export default CustomQuestionTextArea;
