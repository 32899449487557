import { CUSTOM_BLOCK_TYPES, splitTexString } from "helpers/questionHelpers";

export const mergeSameTypeBlocks = (blocks) => {
    let resolved = [...blocks];
    let anyChanged = true;
    while (anyChanged && resolved.length > 1) {
        anyChanged = false;
        for (let i = 0; i < resolved.length - 1; i++) {
            const a = resolved[i];
            const b = resolved[i + 1];
            if (a.type === b.type) {
                resolved[i] = { ...a, val: a.val + " " + b.val };
                resolved.splice(i + 1, 1);
                i--;
            }
        }
    }
    return resolved;
};

export const parseValueString = (valStr) => {
    return mergeSameTypeBlocks(
        splitTexString(valStr)
            .filter(
                ({ val = "", type = null }) =>
                    val?.trim?.() || type === CUSTOM_BLOCK_TYPES.NEW_LINE
            )
            .map(({ val, type }) => ({
                val:
                    type !== CUSTOM_BLOCK_TYPES.NEW_LINE
                        ? val?.toString()
                        : null,
                type
            }))
    );
};

export const DEFAULT_BLOCKS_VALUE = [];

export const blocksToString = (vals) => {
    return vals.reduce((acc, v) => {
        let val;
        switch (v.type) {
            case CUSTOM_BLOCK_TYPES.MATH:
                val = v?.val ? `$${v.val.toString()}$` : " ";
                if (val.slice(-1) !== " ") {
                    val += " ";
                }
                break;
            case CUSTOM_BLOCK_TYPES.TEXT:
                val = v?.val ? v.val.toString() : " ";
                if (val.slice(-1) !== " ") {
                    val += " ";
                }
                break;
            case CUSTOM_BLOCK_TYPES.NEW_LINE:
                val = "<br/> ";
                break;
            default:
                val = "";
        }
        return acc + val;
    }, "");
};
