import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Drawer, Tooltip } from "antd";
import MathLiveKeyboardContainer from "./MathLiveKeyboardContainer";
import CustomQuestionCard from "./CustomQuestionCard";
import { EyeFilled, EyeOutlined } from "@ant-design/icons";
import SuggestionKeyboard from "./SuggestionKeyboard";
import customQHowTo from "assets/illustrations/how-to-custom-qs.gif";
import SvgIconTab from "assets/icons/IconTab";

const INITIIAL_FOCUS = { type: "q", index: null };

function CustomQuestionModal({ initialState = null, open, onClose, onInsert }) {
    const [question, setQuestion] = useState(initialState?.question ?? null);
    const [answer, setAnswer] = useState(initialState?.answer ?? null);
    const [showPreview, setShowPreview] = useState(false);
    const [focusedBlock, setFocusedBlock] = useState(INITIIAL_FOCUS);
    const questionRef = useRef(null);
    const answerRef = useRef(null);
    const editing = initialState?.question || initialState?.answer;

    const replaceActiveBlock = useCallback(
        (...args) =>
            focusedBlock?.type === "q"
                ? questionRef?.current?.replaceActiveBlock?.(...args)
                : answerRef?.current?.replaceActiveBlock?.(...args),
        [focusedBlock?.type]
    );

    useEffect(() => {
        setQuestion(initialState?.question ?? null);
        setAnswer(initialState?.answer ?? null);
    }, [initialState]);

    useEffect(() => {
        if (open) {
            setFocusedBlock(INITIIAL_FOCUS);
        }
    }, [open]);

    return (
        <Drawer
            title={null}
            closable={false}
            maskClosable={false}
            open={open}
            destroyOnClose={editing}
            onCancel={onClose}
            footer={null}
            width={"100vw"}
            className={"custom-question-modal"}
        >
            <div className={"custom-question-modal__header"}>
                <h1>Create Custom Question</h1>
                <h2 class={"danger"}>BETA</h2>
                <Button type={"default"} onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    icon={showPreview ? <EyeFilled /> : <EyeOutlined />}
                    onClick={() => setShowPreview((prev) => !prev)}
                >
                    {showPreview ? "Hide Preview" : "Show Preview"}
                </Button>
                <Tooltip
                    trigger={
                        !question?.trim?.() || !answer?.trim?.()
                            ? "hover"
                            : "none"
                    }
                    title={
                        (!question?.trim?.() ? "Question" : "Answer") +
                        " cannot be empty"
                    }
                >
                    <Button
                        type={"primary"}
                        disabled={!question?.trim?.() || !answer?.trim?.()}
                        onClick={() => {
                            onInsert(question, answer);
                            setShowPreview(false);
                            onClose();
                        }}
                    >
                        {editing ? "Save Changes" : "Insert Question"}
                    </Button>
                </Tooltip>
            </div>
            <div className={"custom-question-modal__content"}>
                <div className={"custom-question-modal__question-area"}>
                    <CustomQuestionCard
                        ref={questionRef}
                        title={"Question"}
                        initialValue={question}
                        onChange={setQuestion}
                        showPreview={showPreview}
                        focusedBlock={
                            focusedBlock?.type === "q"
                                ? focusedBlock?.index
                                : null
                        }
                        setFocusedBlock={(i) =>
                            setFocusedBlock({ type: "q", index: i })
                        }
                    />
                </div>
                <div className={"custom-question-modal__answer-area"}>
                    <CustomQuestionCard
                        ref={answerRef}
                        title={"Answer"}
                        initialValue={answer}
                        onChange={setAnswer}
                        showPreview={showPreview}
                        focusedBlock={
                            focusedBlock?.type === "a"
                                ? focusedBlock?.index
                                : null
                        }
                        setFocusedBlock={(i) =>
                            setFocusedBlock({ type: "a", index: i })
                        }
                    />
                </div>
                <div className={"custom-question-modal__keyboard"}>
                    {focusedBlock?.index === null ? (
                        <div
                            className={"custom-question-modal__keyboard__none "}
                        >
                            <img
                                src={customQHowTo}
                                alt={"how-to animation"}
                                className={"animation"}
                            />
                            <p>
                                <strong>Tip: </strong>You can press{" "}
                                <span className={"info-tab"}>
                                    <span>TAB </span>
                                    <SvgIconTab />
                                </span>{" "}
                                to switch or open a new box.
                            </p>
                        </div>
                    ) : (
                        <SuggestionKeyboard
                            className={"custom-question-modal__keyboard__text "}
                            replaceActiveBlock={replaceActiveBlock}
                        />
                    )}
                    <MathLiveKeyboardContainer
                        visible={open}
                        className={"custom-question-modal__keyboard__mathlive"}
                    />
                </div>
            </div>
        </Drawer>
    );
}

export default CustomQuestionModal;
