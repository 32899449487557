import * as React from "react";

const SvgIconInsertEquation = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={"1em"}
        height={"1em"}
        viewBox={"0 0 14 14"}
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M1.692 9.24a.8.8 0 0 0-1.384.804l1.384-.804Zm1.803.245.669.44a.776.776 0 0 0 .01-.018l-.679-.422Zm-2.66-6.04a.8.8 0 0 0 1.574.292L.836 3.445Zm2.159-1.944-.05-.798.05.798Zm5.988-.376-.05-.798.05.798Zm2.046.673a.8.8 0 0 0-.1-1.596l.1 1.596ZM7.971 5.745l.097.794 1.588-.194-.097-.794-1.588.194ZM.308 10.044c.435.748 1.183 1.115 1.956 1.061.75-.053 1.447-.492 1.9-1.18l-1.337-.879c-.215.327-.485.45-.675.463-.167.012-.333-.05-.46-.269l-1.384.804Zm3.867-.137c.498-.802.767-1.76.92-3.075.15-1.308.194-3.038.196-5.424l-1.6-.002c-.002 2.39-.047 4.036-.186 5.242-.14 1.2-.366 1.894-.69 2.415l1.36.844Zm-1.766-6.17c.075-.408.252-.823.452-1.127.1-.151.19-.251.254-.305.077-.066.052-.013-.07-.006L2.943.703c-.372.023-.67.216-.866.383-.21.18-.396.406-.553.644a4.804 4.804 0 0 0-.69 1.715l1.574.292Zm.635-1.438 1.497-.093-.1-1.597-1.497.094.1 1.596Zm1.497-.093 4.491-.282-.1-1.597-4.491.282.1 1.597Zm4.491-.282 1.996-.126-.1-1.596-1.996.125.1 1.597Zm-.84-.922c-.236 1.505-.52 2.292-.221 4.743l1.588-.194c-.267-2.187-.03-2.74.214-4.302l-1.581-.247Z"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M9.762 13.82a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm.5-5a.5.5 0 0 0-1 0v1h-1a.5.5 0 0 0 0 1h1v1a.5.5 0 1 0 1 0v-1h1a.5.5 0 1 0 0-1h-1v-1Z"
            clipRule="evenodd"
        />
    </svg>
);
export default SvgIconInsertEquation;
