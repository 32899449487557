import React, {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState
} from "react";
import {
    Button,
    Checkbox,
    Form,
    Input,
    message,
    Popover,
    Space,
    Spin,
    Tag,
    Tooltip
} from "antd";
import Icon, {
    BugOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    LeftCircleOutlined,
    ZoomInOutlined
} from "@ant-design/icons";
import { apiGet, apiPut } from "helpers/api";
import {
    getChart,
    getImage,
    recallTagOptions,
    TexComponent
} from "helpers/questionHelpers";
import { getStepIndex, TourContext } from "features/tour/Tour";
import { withCookies } from "react-cookie";
import SvgIconValuesReload from "assets/icons/IconValuesReload";
import SvgIconReload from "assets/icons/IconReload";
import { tooltipColour } from "helpers/starterHelpers";
import { registerChartJSElements } from "helpers/chartHelpers";
import FullscreenChartModal from "./components/FullscreenChartModal";
import SvgCalculatorAllowed from "assets/icons/CalculatorAllowedIcon";
import SvgIconRedoReverse from "assets/icons/IconRedoReverse";
import SvgIconInsertEquation from "assets/icons/IconInsertEquation";
import SvgIconEdit from "assets/icons/IconEdit";
import SvgFullScreenOpen from "assets/icons/FullScreenOpen";

registerChartJSElements();

export const fontSizeToScale = (fontSize) => (fontSize + 3) / 4;
export const fontSizeToRem = (fontSize) => fontSizeToScale(fontSize) + "rem";
export const CUSTOM_Q_ID = "CUSTOM";

function QuestionContainer({
    children,
    error,
    hasQuestion,
    loading,
    isAnswer,
    defaultFontSize,
    topicSelector,
    fontSize,
    setFontSize,
    hasImage,
    hasTallImage,
    onClick = () => {},
    enableCustomQuestions,
    showCustomQuestionModal
}) {
    const textRef = useRef();
    const [toggleReSize, setToggleResize] = useState(false);
    const triggerReSize = () => setToggleResize((p) => !p);

    const resizeFn = useCallback(() => {
        if (!textRef?.current) {
            return;
        }

        setFontSize((prev) => {
            const overflowX =
                textRef?.current?.scrollWidth - textRef?.current?.clientWidth;
            const overflowY =
                textRef?.current?.scrollHeight - textRef?.current?.clientHeight;

            const overflow =
                overflowX > 0
                    ? overflowX
                    : overflowY > 0
                      ? overflowY
                      : overflowX + overflowY;
            const isOverflowing = overflow > 1;
            if (isOverflowing && prev > 0) {
                return prev - 1;
            }
            return prev;
        });
    }, [setFontSize]);

    useEffect(() => {
        resizeFn();
    }, [toggleReSize, resizeFn]);

    useEffect(() => {
        const interval = setInterval(triggerReSize, 200);
        return () => clearInterval(interval);
    }, []);

    const clickable = Boolean(hasQuestion && !loading);

    return (
        <div
            className={"question " + (clickable ? " clickable " : "")}
            onClick={() => clickable && onClick()}
        >
            {hasQuestion ? (
                loading ? (
                    <div className="spinContainer">
                        <Spin />
                    </div>
                ) : (
                    <div
                        className={
                            "questionWrapper " +
                            ((hasTallImage || hasTallImage == null) && hasImage
                                ? "row"
                                : "")
                        }
                        ref={textRef}
                        style={{
                            fontSize: fontSizeToRem(
                                fontSize === 0 ? 0 : fontSize || defaultFontSize
                            )
                        }}
                    >
                        {error ? (
                            <p style={{ color: "red" }}>
                                {"Error: " + error.message}
                            </p>
                        ) : (
                            children
                        )}
                    </div>
                )
            ) : (
                !isAnswer &&
                (enableCustomQuestions ? (
                    <div className={"questionCreationSelector"}>
                        <div>
                            <h3>Our Library</h3>
                            {topicSelector}
                        </div>
                        <div>
                            <h3>Create</h3>
                            <Button
                                icon={
                                    <Icon
                                        component={() => (
                                            <SvgIconInsertEquation />
                                        )}
                                    />
                                }
                                onClick={() => showCustomQuestionModal()}
                            >
                                Custom Question
                            </Button>
                        </div>
                    </div>
                ) : (
                    topicSelector
                ))
            )}
        </div>
    );
}

export function StaticQuestionCard({
    questionComponent,
    title = "Question Preview",
    answerTitle = "Answer Preview",
    extraClassNames = undefined,
    questionFont = 6
}) {
    const defaultFontSize = questionFont;
    const [questionFontSize, setQuestionFontSize] = useState(defaultFontSize);
    const [answerFontSize, setAnswerFontSize] = useState(defaultFontSize);
    const [hasTallImage, setHasTallImage] = useState(null);
    const [answerHasTallImage, setAnswerHasTallImage] = useState(null);
    const [showAnswer, setShowAnswer] = useState(false);
    const updateQTallImage = useCallback(
        (node) => {
            if (
                questionComponent?.imageUrlSuffix &&
                node &&
                node instanceof HTMLImageElement
            ) {
                const imageRatio = node.naturalHeight / node.naturalWidth;
                setHasTallImage(imageRatio >= 0.9);
            }
        },
        [questionComponent?.imageUrlSuffix]
    );
    const imageRef = useCallback(
        (node) => {
            updateQTallImage(node);
            return { current: node };
        },
        [updateQTallImage]
    );
    const updateAnswerTallImage = useCallback(
        (node) => {
            if (
                questionComponent?.answerImageUrlSuffix &&
                node &&
                node instanceof HTMLImageElement
            ) {
                const imageRatio = node.naturalHeight / node.naturalWidth;
                setAnswerHasTallImage(imageRatio >= 0.9);
            }
        },
        [questionComponent?.answerImageUrlSuffix]
    );
    const answerImageRef = useCallback(
        (node) => {
            updateAnswerTallImage(node);
            return { current: node };
        },
        [updateAnswerTallImage]
    );

    useEffect(() => {
        setQuestionFontSize(defaultFontSize);
        setAnswerFontSize(defaultFontSize);
    }, [defaultFontSize]);

    function CardOutline({
        title,
        isAnswer = false,
        fontSize,
        setFontSize,
        defaultFontSize,
        hasImage,
        hasTallImage,
        children
    }) {
        const questionContainer = useMemo(
            () => (
                <QuestionContainer
                    hasQuestion={true}
                    loading={false}
                    isAnswer={isAnswer}
                    fontSize={fontSize}
                    defaultFontSize={defaultFontSize}
                    setFontSize={setFontSize}
                    error={null}
                    children={children}
                    topicSelector={null}
                    fullscreen={false}
                    hasImage={hasImage}
                    hasTallImage={hasTallImage}
                    onClick={() => setShowAnswer(!isAnswer)}
                />
            ),
            [
                isAnswer,
                fontSize,
                defaultFontSize,
                setFontSize,
                children,
                hasImage,
                hasTallImage
            ]
        );

        return (
            <>
                <div className="question-number">
                    <h1>{title}</h1>
                    <div className="calc-icon">
                        {questionComponent &&
                            questionComponent?.calculatorAllowed !==
                                undefined && (
                                <SvgCalculatorAllowed
                                    allowed={
                                        questionComponent.calculatorAllowed
                                    }
                                />
                            )}
                    </div>
                    <div className={"questionHeaderRight"}>
                        <div className="q-refresh-fn"></div>
                    </div>
                </div>
                {questionContainer}
                <div className="question-fn">
                    {!isAnswer && <div className="q-back-fn"></div>}
                    <div className="q-answer-fn">
                        {showAnswer ? (
                            <Tooltip title="Hide Answer" color={tooltipColour}>
                                <button
                                    onClick={() => {
                                        setShowAnswer(false);
                                    }}
                                >
                                    <CloseCircleTwoTone twoToneColor="#1660e7" />
                                </button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Show Answer" color="#52c41a">
                                <button
                                    onClick={() => {
                                        setShowAnswer(true);
                                    }}
                                >
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </>
        );
    }

    return (
        <div
            className={`flippableCard ${
                showAnswer ? "flipped" : undefined
            } card-w1 card-h1 `}
        >
            <div
                className={`question-card flippableCard__face flippableCard__face--front ${extraClassNames}`}
            >
                <CardOutline
                    title={title}
                    fontSize={questionFontSize}
                    setFontSize={setQuestionFontSize}
                    defaultFontSize={defaultFontSize}
                    hasImage={
                        questionComponent && questionComponent?.imageUrlSuffix
                    }
                    hasTallImage={hasTallImage}
                >
                    {questionComponent &&
                        questionComponent?.imageUrlSuffix &&
                        getImage(
                            questionComponent.imageUrlSuffix,
                            "questionImage",
                            undefined,
                            imageRef,
                            (e) => updateQTallImage(e.target)
                        )}
                    {questionComponent &&
                        questionComponent?.chartData &&
                        getChart(questionComponent?.chartData, "questionImage")}
                    <span>
                        {questionComponent && questionComponent.question}
                    </span>
                </CardOutline>
            </div>

            <div
                className={`question-card  flippableCard__face flippableCard__face--back  ${extraClassNames}`}
            >
                <CardOutline
                    title={answerTitle}
                    isAnswer={true}
                    fontSize={answerFontSize}
                    setFontSize={setAnswerFontSize}
                    defaultFontSize={defaultFontSize}
                    hasImage={
                        questionComponent &&
                        questionComponent?.answerImageUrlSuffix
                    }
                    hasTallImage={answerHasTallImage}
                >
                    {questionComponent &&
                        questionComponent?.answerImageUrlSuffix &&
                        getImage(
                            questionComponent.answerImageUrlSuffix,
                            "questionImage",
                            undefined,
                            answerImageRef,
                            (e) => updateAnswerTallImage(e.target)
                        )}
                    {questionComponent &&
                        questionComponent?.answerChartData &&
                        getChart(
                            questionComponent?.answerChartData,
                            "questionImage"
                        )}
                    <span>
                        {questionComponent && (
                            <span className={"answer "}>
                                {questionComponent.answer}
                            </span>
                        )}
                    </span>
                </CardOutline>
            </div>
        </div>
    );
}

function CardOutline({
    title,
    id,
    setId,
    questionNum,
    isLoading,
    questionComponent,
    setQuestionComponent,
    error,
    isAnswer = false,
    isStarter,
    showAnswer,
    setShowAnswer,
    fontSize,
    setFontSize,
    calculatorAllowed,
    setCalculatorAllowed,
    recallTag,
    addRecallTag,
    setRecallTagData,
    showRecallTags,
    setShowRecallTags,
    fullscreen,
    hasRecallTags,
    showIssue,
    setShowIssue,
    setRandomSeed,
    addPreviousID,
    popPreviousId,
    previousIds,
    previousValueSeeds,
    refreshValues,
    refreshTemplate,
    whiteboardEnabled,
    hasQuestion,
    setHasQuestion,
    setReRenderToggle,
    reportIssueForm,
    isCustomQ = false,
    defaultFontSize,
    hasImage,
    hasTallImage,
    setDifficulty,
    topicSelector,
    setShowSketch,
    enableCustomQuestions,
    showCustomQuestionModal,
    children
}) {
    const interactive = (!isAnswer && !showAnswer) || (isAnswer && showAnswer);
    const tourContext = useContext(TourContext);

    const [showChartFullscreen, setShowChartFullscreen] = useState(false);
    const [showEditQTooltip, setShowEditQTooltip] = useState(false);

    const questionContainer = useMemo(
        () => (
            <QuestionContainer
                hasQuestion={hasQuestion}
                loading={isLoading || (!questionComponent && !error)}
                isAnswer={isAnswer}
                fontSize={fontSize}
                defaultFontSize={defaultFontSize}
                setFontSize={setFontSize}
                error={error}
                children={children}
                topicSelector={topicSelector}
                fullscreen={fullscreen}
                hasImage={hasImage}
                hasTallImage={hasTallImage}
                onClick={() => setShowAnswer(!isAnswer)}
                enableCustomQuestions={enableCustomQuestions}
                showCustomQuestionModal={showCustomQuestionModal}
            />
        ),
        [
            hasQuestion,
            isLoading,
            questionComponent,
            error,
            isAnswer,
            fontSize,
            defaultFontSize,
            setFontSize,
            children,
            topicSelector,
            fullscreen,
            hasImage,
            hasTallImage,
            enableCustomQuestions,
            showCustomQuestionModal,
            setShowAnswer
        ]
    );

    return (
        <>
            <div className="question-number">
                <FullscreenChartModal
                    isOpen={showChartFullscreen}
                    setIsOpen={setShowChartFullscreen}
                    chartData={
                        isAnswer
                            ? questionComponent?.answerChartData
                            : questionComponent?.chartData
                    }
                />
                <h1>{title}</h1>
                {isStarter && hasRecallTags && (!fullscreen || recallTag) && (
                    <Popover
                        disabled={
                            tourContext.isOpen &&
                            tourContext.currentStep ===
                                getStepIndex("firstQuestion")
                        }
                        overlayClassName={"tagsList questionCardTags"}
                        content={
                            <div
                                // className={"tagsList questionCardTags"}
                                data-tour={
                                    isStarter && !isAnswer && questionNum === 1
                                        ? "recallTagList"
                                        : undefined
                                }
                            >
                                {recallTagOptions.map((t) => (
                                    <div
                                        className={"tagWrapper clickable"}
                                        key={t.name}
                                        onClick={() => {
                                            if (!interactive) {
                                                return;
                                            }
                                            setRecallTagData(t);

                                            if (
                                                tourContext.isOpen &&
                                                tourContext.currentStep ===
                                                    getStepIndex(
                                                        "recallTagSelect"
                                                    )
                                            ) {
                                                tourContext.nextStep();
                                            }
                                            setShowRecallTags(false);
                                        }}
                                    >
                                        <Tag color={t.colour}>{t.name}</Tag>
                                    </div>
                                ))}
                            </div>
                        }
                        trigger="click"
                        open={interactive && showRecallTags}
                        onOpenChange={(visible) => {
                            if (!interactive) {
                                return;
                            }
                            if (
                                tourContext.isOpen &&
                                tourContext.currentStep ===
                                    getStepIndex("recallTagOpen")
                            ) {
                                tourContext.nextStep();
                                setShowRecallTags(visible);
                            } else if (
                                !tourContext.isOpen ||
                                (tourContext.isOpen &&
                                    tourContext.currentStep !==
                                        getStepIndex("recallTagSelect"))
                            ) {
                                setShowRecallTags(visible);
                            }
                        }}
                    >
                        <div
                            className={"recall-tag-container clickable"}
                            data-tour={
                                isStarter && !isAnswer && questionNum === 1
                                    ? "recallTag"
                                    : undefined
                            }
                        >
                            {recallTag || addRecallTag}
                        </div>
                    </Popover>
                )}
                <div
                    className="calc-icon clickable"
                    onClick={() => setCalculatorAllowed((prev) => !prev)}
                >
                    {hasQuestion && questionComponent && (
                        <SvgCalculatorAllowed allowed={calculatorAllowed} />
                    )}
                </div>
                <div className={"questionHeaderRight"}>
                    <div className="q-refresh-fn">
                        {hasQuestion && (
                            <>
                                {((!isAnswer &&
                                    questionComponent?.chartData?.type) ||
                                    (isAnswer &&
                                        questionComponent?.answerChartData
                                            ?.type)) && (
                                    <Tooltip
                                        title="View Chart Fullscreen"
                                        color={tooltipColour}
                                    >
                                        <button
                                            disabled={Boolean(!id || error)}
                                            onClick={() => {
                                                if (!interactive) {
                                                    return;
                                                }
                                                setShowChartFullscreen(true);
                                            }}
                                            aria-label={"Change Values"}
                                        >
                                            <ZoomInOutlined />
                                        </button>
                                    </Tooltip>
                                )}
                                {!isCustomQ && (
                                    <Tooltip
                                        title="Report an Incorrect Answer"
                                        color={tooltipColour}
                                        trigger={
                                            showIssue !== "popover"
                                                ? "hover"
                                                : "none"
                                        }
                                        open={
                                            interactive &&
                                            showIssue === "tooltip"
                                        }
                                        onOpenChange={(hovered) => {
                                            if (!interactive) {
                                                return;
                                            }
                                            setShowIssue(
                                                hovered ? "tooltip" : null
                                            );
                                        }}
                                    >
                                        <Popover
                                            trigger="click"
                                            open={
                                                interactive &&
                                                showIssue === "popover"
                                            }
                                            onOpenChange={(clicked) => {
                                                if (!interactive) {
                                                    return;
                                                }
                                                setShowIssue(
                                                    clicked ? "popover" : null
                                                );
                                            }}
                                            title={
                                                <b
                                                    style={{
                                                        fontWeight: 700
                                                    }}
                                                >
                                                    Report an Issue With This
                                                    Question
                                                </b>
                                            }
                                            content={reportIssueForm}
                                        >
                                            <button
                                                style={{
                                                    color: questionComponent?.issueReported
                                                        ? "red"
                                                        : ""
                                                }}
                                            >
                                                <BugOutlined />
                                            </button>
                                        </Popover>
                                    </Tooltip>
                                )}
                                {!isCustomQ && previousIds?.length > 0 && (
                                    <Tooltip
                                        title="Previous Question"
                                        color={tooltipColour}
                                    >
                                        <button
                                            disabled={Boolean(!id)}
                                            onClick={() => {
                                                if (!interactive) {
                                                    return;
                                                }
                                                setId(
                                                    previousIds.slice(-1).pop()
                                                );
                                                setRandomSeed(
                                                    previousValueSeeds
                                                        .slice(-1)
                                                        .pop()
                                                );
                                                popPreviousId();
                                            }}
                                            aria-label={"Previous Question"}
                                        >
                                            <Icon
                                                component={() => (
                                                    <SvgIconRedoReverse />
                                                )}
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                                {!isCustomQ && (
                                    <Tooltip
                                        title="Change Values"
                                        color={tooltipColour}
                                    >
                                        <button
                                            disabled={Boolean(!id || error)}
                                            onClick={() => {
                                                if (!interactive) {
                                                    return;
                                                }
                                                refreshValues();
                                            }}
                                            aria-label={"Change Values"}
                                        >
                                            <Icon
                                                component={() => (
                                                    <SvgIconValuesReload />
                                                )}
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                                {!isCustomQ ? (
                                    <Tooltip
                                        title="Change Question"
                                        color={tooltipColour}
                                    >
                                        <button
                                            disabled={Boolean(!id)}
                                            onClick={() => {
                                                if (!interactive) {
                                                    return;
                                                }
                                                refreshTemplate();
                                            }}
                                            aria-label={"Change Question"}
                                        >
                                            <Icon
                                                component={() => (
                                                    <SvgIconReload />
                                                )}
                                            />
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip
                                        title="Edit Question"
                                        color={tooltipColour}
                                        open={showEditQTooltip}
                                        onOpenChange={setShowEditQTooltip}
                                    >
                                        <button
                                            disabled={Boolean(!id)}
                                            onClick={() => {
                                                if (!interactive) {
                                                    return;
                                                }
                                                showCustomQuestionModal();
                                                setShowEditQTooltip(false);
                                            }}
                                            aria-label={"Edit Question"}
                                        >
                                            <Icon
                                                component={() => (
                                                    <SvgIconEdit />
                                                )}
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                                {whiteboardEnabled && (
                                    <Tooltip
                                        title="Whiteboard Mode"
                                        color={tooltipColour}
                                    >
                                        <button
                                            className={"whiteboard-btn"}
                                            onClick={() => {
                                                if (!interactive) {
                                                    return;
                                                }
                                                if (
                                                    questionNum === 1 &&
                                                    tourContext.isOpen &&
                                                    tourContext.currentStep ===
                                                        getStepIndex(
                                                            "whiteboardOpen"
                                                        )
                                                ) {
                                                    tourContext.nextStep();
                                                }
                                                setShowSketch(true);
                                            }}
                                            aria-label={"Open Whiteboard Mode"}
                                            data-tour={
                                                isAnswer && questionNum === 1
                                                    ? "whiteboardOpen"
                                                    : undefined
                                            }
                                        >
                                            <Icon
                                                component={() => (
                                                    <SvgFullScreenOpen />
                                                )}
                                            />
                                        </button>
                                    </Tooltip>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {questionContainer}
            {hasQuestion && (
                <div className="question-fn">
                    {!isAnswer && (
                        <div className="q-back-fn">
                            <Tooltip
                                title="Change Topic"
                                color={tooltipColour}
                                placement="right"
                            >
                                <button
                                    data-tour={
                                        isStarter && questionNum === 2
                                            ? "questionBack"
                                            : undefined
                                    }
                                    onClick={() => {
                                        setHasQuestion(false);
                                        setDifficulty(null);
                                        setId(null);
                                        setQuestionComponent(null);
                                        if (
                                            questionNum === 2 &&
                                            tourContext.isOpen &&
                                            tourContext.currentStep ===
                                                getStepIndex("questionBack")
                                        ) {
                                            tourContext.nextStep();
                                        }
                                        setReRenderToggle((r) => !r);
                                    }}
                                >
                                    <LeftCircleOutlined />
                                </button>
                            </Tooltip>
                        </div>
                    )}
                    <div
                        className="q-answer-fn"
                        data-tour={
                            questionNum === 1 ? "showSingleAnswer" : undefined
                        }
                    >
                        {showAnswer ? (
                            <Tooltip title="Hide Answer" color={tooltipColour}>
                                <button
                                    onClick={() => {
                                        if (
                                            questionNum === 1 &&
                                            tourContext.isOpen &&
                                            tourContext.currentStep ===
                                                getStepIndex("showSingleAnswer")
                                        ) {
                                            tourContext.nextStep();
                                        }
                                        setShowAnswer(false);
                                    }}
                                >
                                    <CloseCircleTwoTone twoToneColor="#1660e7" />
                                </button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Show Answer" color="#52c41a">
                                <button
                                    onClick={() => {
                                        if (
                                            tourContext.isOpen &&
                                            tourContext.currentStep ===
                                                getStepIndex("showSingleAnswer")
                                        ) {
                                            tourContext.nextStep();
                                        }
                                        setShowAnswer(true);
                                    }}
                                >
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
const QuestionCard = forwardRef((props, ref) => {
    const {
        cookies,
        questionNum,
        isStarter,
        questionFont,
        width,
        height,
        senBg,
        extraClassNames = undefined,
        title,
        answerTitle,
        id,
        setId,
        subtopic,
        difficulty,
        setDifficulty,
        randomSeed,
        setRandomSeed,
        getNewRandomSeed,
        calculatorAllowed,
        setCalculatorAllowed,
        topicSelector,
        setShowSketch,
        enableCustomQuestions = false,
        showCustomQuestionModal,
        showAnswer,
        setShowAnswer,
        questionComponent,
        setQuestionComponent,
        setStaleEdits,
        hasRecallTags,
        fullscreen,
        recallTagData,
        setRecallTagData,
        customQuestion = null,
        customAnswer = null,
        whiteboardEnabled = true
    } = props;
    const isCustomQ = id === CUSTOM_Q_ID || customQuestion || customAnswer;
    const [reRenderToggle, setReRenderToggle] = useState(false);
    const [error, setError] = useState(null);
    const onQuestionError = useCallback(
        (err) =>
            err &&
            setError({
                message: "Unable to display question. Error message: " + err
            }),
        []
    );
    const onAnswerError = useCallback(
        (err) =>
            err &&
            setError({
                message: "Unable to display answer. Error message: " + err
            }),
        []
    );
    const [hasQuestion, setHasQuestion] = useState(Boolean(id || difficulty));
    const PREVIOUS_IDS_LIMIT = 3;
    const [previousIds, setPreviousIds] = useState([]);
    const [previousValueSeeds, setValueSeeds] = useState([]);
    const addPreviousID = (newId, newSeed) => {
        setPreviousIds((prevIds) => {
            const copy = [...prevIds];
            copy.push(newId);
            return copy.slice(-(PREVIOUS_IDS_LIMIT - 1));
        });
        setValueSeeds((prevSeeds) => {
            const copy = [...prevSeeds];
            copy.push(newSeed);
            return copy.slice(-(PREVIOUS_IDS_LIMIT - 1));
        });
    };

    const popPreviousId = () => {
        setPreviousIds((prevIds) => {
            const copy = [...prevIds];
            copy.pop();
            return copy.slice(-(PREVIOUS_IDS_LIMIT - 1));
        });
        setValueSeeds((prevSeeds) => {
            const copy = [...prevSeeds];
            copy.pop();
            return copy.slice(-(PREVIOUS_IDS_LIMIT - 1));
        });
    };

    const [loading, setLoading] = useState([]);
    const isLoading = Boolean(loading?.length);
    const registerNewLoading = () =>
        setLoading((prev) => {
            const copy = [...prev];
            copy.push(true);
            return copy;
        });
    const completeLoading = () =>
        setLoading((prev) => {
            const copy = [...prev];
            copy.pop();
            return copy;
        });
    const [showRecallTags, setShowRecallTags] = useState(false);

    const defaultFontSize = questionFont;
    const [questionFontSize, setQuestionFontSize] = useState(defaultFontSize);
    const [answerFontSize, setAnswerFontSize] = useState(defaultFontSize);
    const [hasTallImage, setHasTallImage] = useState(null);
    const [answerHasTallImage, setAnswerHasTallImage] = useState(null);
    const [fetchNext, setFetchNext] = useState(false);

    useEffect(() => {
        setQuestionFontSize(defaultFontSize);
        setAnswerFontSize(defaultFontSize);
    }, [defaultFontSize]);

    const [showIssue, setShowIssue] = useState(null);

    const [csrfToken] = useState(cookies.get("XSRF-TOKEN"));
    const updateQTallImage = useCallback(
        (node) => {
            if (
                questionComponent?.imageUrlSuffix &&
                node &&
                node instanceof HTMLImageElement
            ) {
                const imageRatio = node.naturalHeight / node.naturalWidth;
                setHasTallImage(imageRatio >= 0.9);
            }
        },
        [questionComponent?.imageUrlSuffix]
    );
    const imageRef = useCallback(
        (node) => {
            updateQTallImage(node);
            return { current: node };
        },
        [updateQTallImage]
    );
    const updateAnswerTallImage = useCallback(
        (node) => {
            if (
                questionComponent?.answerImageUrlSuffix &&
                node &&
                node instanceof HTMLImageElement
            ) {
                const imageRatio = node.naturalHeight / node.naturalWidth;
                setAnswerHasTallImage(imageRatio >= 0.9);
            }
        },
        [questionComponent?.answerImageUrlSuffix]
    );
    const answerImageRef = useCallback(
        (node) => {
            updateAnswerTallImage(node);
            return { current: node };
        },
        [updateAnswerTallImage]
    );

    const refreshValues = () => {
        if (isLoading) {
            return;
        }
        getNewRandomSeed();
    };

    const resetFontSize = () => {
        setQuestionFontSize(defaultFontSize);
        setAnswerFontSize(defaultFontSize);
    };

    const refreshTemplate = () => {
        if (isLoading) {
            return;
        }
        setFetchNext(true);
        // setStaleEdits(true);
        resetFontSize();
    };
    useImperativeHandle(ref, () => ({
        refreshValues,
        refreshTemplate,
        resetFontSize
    }));

    useEffect(() => {
        setHasQuestion(Boolean(difficulty || id));
    }, [difficulty, id]);

    useEffect(() => {
        if (isLoading || (!id && !(subtopic && difficulty && randomSeed))) {
            return;
        }
        if (isCustomQ) {
            setQuestionComponent({
                id: CUSTOM_Q_ID,
                question: <TexComponent tex={customQuestion ?? ""} />,
                answer: <TexComponent tex={customAnswer ?? ""} />,
                fontSize: fontSizeToScale(questionFont),
                answerFontSize: fontSizeToScale(questionFont),
                calculatorAllowed: null
            });
            setShowAnswer(false);
            return;
        }
        registerNewLoading();
        setQuestionComponent(null);
        setError(null);
        setShowAnswer(false);
        let params;
        let endpoint;
        let valueSeed;
        if (!fetchNext) {
            valueSeed = randomSeed || Math.floor(Math.random() * 10000); // Fallback if randomSeed not set
            // Get resolved question for current data
            endpoint = "/resolved-question";
            if (id) {
                // Get resolved question for current ID (current or new valueSeed) -- Loading starter or re-generating values
                params = `id=${encodeURIComponent(id)}`;
            } else {
                // Selecting question for the first time (no ID yet)
                params =
                    `subtopic=${encodeURIComponent(subtopic)}` +
                    `&difficulty=${encodeURIComponent(difficulty)}` +
                    `&questionSeed=${encodeURIComponent(randomSeed)}`;
            }
            params += `&valueSeed=${encodeURIComponent(valueSeed)}`;
        } else {
            addPreviousID(id, randomSeed);
            // Fetch a resolved question with a new template (re-generate)
            endpoint = "/new-resolved-question";
            valueSeed = Math.floor(Math.random() * 10000);
            let prevIds = previousIds ?? [];
            if (id) {
                params = `id=${encodeURIComponent(id)}`;
                prevIds = prevIds.concat(id);
            } else {
                params =
                    `subtopic=${encodeURIComponent(subtopic)}` +
                    `&difficulty=${encodeURIComponent(difficulty)}`;
            }
            params +=
                `&valueSeed=${encodeURIComponent(valueSeed)}` +
                `&ignoreIds=${encodeURIComponent(prevIds)}`;
        }
        setRandomSeed(valueSeed);
        apiGet(
            `/api${endpoint}?${params}`,
            (body) => {
                body.question = (
                    <TexComponent
                        tex={body.question}
                        onParseError={onQuestionError}
                    />
                );
                body.answer = (
                    <TexComponent
                        tex={body.answer}
                        onParseError={onAnswerError}
                    />
                );
                body.fontSize = fontSizeToScale(questionFont);
                body.answerFontSize = fontSizeToScale(questionFont);
                setQuestionComponent(body);
                setCalculatorAllowed(body?.calculatorAllowed);
                if (!id || fetchNext) {
                    setId(body.id);
                }
                if (fetchNext) {
                    setStaleEdits(true);
                }
                completeLoading();
            },
            (err) => {
                if (err.code === 404) {
                    apiGet(
                        `/api/new-question-for-error?${id ? `id=${id}&` : ""}` +
                            `subtopic=${encodeURIComponent(subtopic)}` +
                            `&difficulty=${encodeURIComponent(difficulty)}`,
                        ({ id }) => {
                            if (id) {
                                setId(id);
                                setStaleEdits(true);
                            } else {
                                setError(err);
                                setId(null);
                                setQuestionComponent(null);
                            }
                        },
                        (err) => setError(err)
                    );
                } else {
                    setError(err);
                }
                completeLoading();
            }
        );
        setFetchNext(false);
        // TODO fix dependencies so it doesn't re-trigger on every re-render
        //  -- maybe store prevValue or useRef or something
        //  -- probably also redo how things are stored in questionComponent so that refreshing
        //     can be as fast as ExtensionQuestion/AdminDashboard Question
        // eslint-disable-next-line
    }, [
        subtopic,
        difficulty,
        id,
        randomSeed,
        fetchNext,
        setQuestionComponent,
        reRenderToggle,
        customQuestion,
        customAnswer
    ]);

    const addRecallTag = (
        <Tag
            className="addRecallTag clickable"
            onClick={() => setShowRecallTags(true)}
        >
            + RECALL TAG
        </Tag>
    );

    const getTag = (tagData) => (
        <Tag
            color={tagData.colour}
            closable="true"
            className={"recallTagDisplay"}
            onClose={(e) => {
                e.preventDefault();
                setRecallTagData(null);
            }}
        >
            {tagData.name}
        </Tag>
    );

    const recallTag = recallTagData && getTag(recallTagData);

    const reportIssueForm = (
        <Form
            autoComplete={"off"}
            layout={"vertical"}
            onFinish={(values) => {
                const report = (id, description) =>
                    apiPut(
                        "/api/report-issue?id=" +
                            encodeURIComponent(id) +
                            "&description=" +
                            encodeURIComponent(description),
                        {},
                        csrfToken,
                        () => {
                            message.success(
                                "Thank you for reporting an issue. We will look into it"
                            );
                            setShowIssue(false);
                        }
                    );
                const idParams = `id=${encodeURIComponent(
                    id
                )}&valueSeed=${encodeURIComponent(randomSeed)}`;

                const randomParams = `subtopic=${encodeURIComponent(
                    subtopic
                )}&difficulty=${encodeURIComponent(
                    difficulty
                )}&questionSeed=${encodeURIComponent(randomSeed)}`;

                apiGet(
                    `/api/resolved-question?${
                        id
                            ? idParams
                            : randomParams +
                              `&valueSeed=${encodeURIComponent(randomSeed)}`
                    }`,
                    (body) =>
                        report(
                            id || questionComponent.id,
                            "Type: " +
                                JSON.stringify(values?.type) +
                                ". Description: " +
                                (values.description || "None Given") +
                                ". Question: " +
                                JSON.stringify(body)
                        ),
                    (e) =>
                        apiGet(
                            `/api/resolved-question-id?${randomParams}`,
                            (id) =>
                                report(
                                    id,
                                    "Type: " +
                                        JSON.stringify(values?.type) +
                                        ". Description: " +
                                        (values.description || "None Given") +
                                        ". Question fetch failure message: " +
                                        e.message
                                ),
                            (e) =>
                                message.error(
                                    "Unable to report issue. Message: " +
                                        e.message
                                )
                        )
                );
            }}
        >
            <Form.Item name={"type"}>
                <Checkbox.Group>
                    <Space direction="vertical">
                        <Checkbox value={"Incorrect Answer"}>
                            Incorrect Answer
                        </Checkbox>
                        <Checkbox value={"Impossible Question"}>
                            Impossible Question
                        </Checkbox>
                        <Checkbox value={"Spelling/Grammar/Formatting Mistake"}>
                            Spelling/Grammar/Formatting Mistake
                        </Checkbox>
                    </Space>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item
                name={"description"}
                label={
                    <b style={{ fontWeight: 600 }}>
                        Description (optional but helpful)
                    </b>
                }
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Button key="submit" type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );

    return (
        <div
            className={`flippableCard ${
                showAnswer ? "flipped" : undefined
            } card-w${width} card-h${height} `}
        >
            <div
                key={"card-face-q-" + questionNum}
                className={`question-card flippableCard__face flippableCard__face--front ${extraClassNames}`}
                data-tour={
                    isStarter && questionNum === 1
                        ? "q1"
                        : isStarter && questionNum === 2
                          ? "q2"
                          : undefined
                }
                style={senBg}
            >
                <CardOutline
                    title={title}
                    id={id}
                    setId={setId}
                    fontSize={questionFontSize}
                    setFontSize={setQuestionFontSize}
                    defaultFontSize={defaultFontSize}
                    hasImage={
                        questionComponent && questionComponent?.imageUrlSuffix
                    }
                    hasTallImage={hasTallImage}
                    calculatorAllowed={calculatorAllowed}
                    setCalculatorAllowed={setCalculatorAllowed}
                    isStarter={isStarter}
                    showAnswer={showAnswer}
                    recallTag={recallTag}
                    addRecallTag={addRecallTag}
                    showRecallTags={showRecallTags}
                    setShowRecallTags={setShowRecallTags}
                    fullscreen={fullscreen}
                    hasRecallTags={hasRecallTags}
                    showIssue={showIssue}
                    setShowIssue={setShowIssue}
                    refreshValues={refreshValues}
                    refreshTemplate={refreshTemplate}
                    setRandomSeed={setRandomSeed}
                    previousIds={previousIds}
                    addPreviousID={addPreviousID}
                    popPreviousId={popPreviousId}
                    previousValueSeeds={previousValueSeeds}
                    whiteboardEnabled={whiteboardEnabled}
                    hasQuestion={hasQuestion}
                    setHasQuestion={setHasQuestion}
                    setReRenderToggle={setReRenderToggle}
                    reportIssueForm={reportIssueForm}
                    isCustomQ={isCustomQ}
                    setDifficulty={setDifficulty}
                    topicSelector={topicSelector}
                    setShowSketch={setShowSketch}
                    enableCustomQuestions={enableCustomQuestions}
                    showCustomQuestionModal={showCustomQuestionModal}
                    questionNum={questionNum}
                    isLoading={isLoading}
                    questionComponent={questionComponent}
                    setQuestionComponent={setQuestionComponent}
                    error={error}
                    setShowAnswer={setShowAnswer}
                    setRecallTagData={setRecallTagData}
                >
                    {questionComponent &&
                        questionComponent.imageUrlSuffix &&
                        getImage(
                            questionComponent.imageUrlSuffix,
                            "questionImage",
                            undefined,
                            imageRef,
                            (e) => updateQTallImage(e.target)
                        )}
                    {questionComponent &&
                        questionComponent?.chartData &&
                        getChart(questionComponent?.chartData, "questionImage")}
                    <span>
                        {questionComponent && questionComponent.question}
                    </span>
                </CardOutline>
            </div>

            <div
                key={"card-face-a-" + questionNum}
                className={`question-card  flippableCard__face flippableCard__face--back  ${extraClassNames}`}
                data-tour={isStarter && questionNum === 1 ? "q1" : undefined}
                style={senBg}
            >
                <CardOutline
                    title={answerTitle}
                    id={id}
                    setId={setId}
                    isAnswer={true}
                    fontSize={answerFontSize}
                    setFontSize={setAnswerFontSize}
                    defaultFontSize={defaultFontSize}
                    hasImage={
                        questionComponent &&
                        questionComponent?.answerImageUrlSuffix
                    }
                    hasTallImage={answerHasTallImage}
                    calculatorAllowed={calculatorAllowed}
                    setCalculatorAllowed={setCalculatorAllowed}
                    showAnswer={showAnswer}
                    recallTag={recallTag}
                    addRecallTag={addRecallTag}
                    showRecallTags={showRecallTags}
                    setShowRecallTags={setShowRecallTags}
                    fullscreen={fullscreen}
                    hasRecallTags={hasRecallTags}
                    showIssue={showIssue}
                    setShowIssue={setShowIssue}
                    refreshValues={refreshValues}
                    refreshTemplate={refreshTemplate}
                    setRandomSeed={setRandomSeed}
                    previousIds={previousIds}
                    addPreviousID={addPreviousID}
                    popPreviousId={popPreviousId}
                    previousValueSeeds={previousValueSeeds}
                    whiteboardEnabled={whiteboardEnabled}
                    hasQuestion={hasQuestion}
                    setHasQuestion={setHasQuestion}
                    setReRenderToggle={setReRenderToggle}
                    reportIssueForm={reportIssueForm}
                    isCustomQ={isCustomQ}
                    setDifficulty={setDifficulty}
                    topicSelector={topicSelector}
                    setShowSketch={setShowSketch}
                    enableCustomQuestions={enableCustomQuestions}
                    showCustomQuestionModal={showCustomQuestionModal}
                    isLoading={isLoading}
                    questionComponent={questionComponent}
                    setQuestionComponent={setQuestionComponent}
                    error={error}
                    setShowAnswer={setShowAnswer}
                    setRecallTagData={setRecallTagData}
                >
                    {questionComponent &&
                        questionComponent?.answerImageUrlSuffix &&
                        getImage(
                            questionComponent.answerImageUrlSuffix,
                            "questionImage",
                            undefined,
                            answerImageRef,
                            (e) => updateAnswerTallImage(e.target)
                        )}
                    {questionComponent &&
                        questionComponent?.answerChartData &&
                        getChart(
                            questionComponent?.answerChartData,
                            "questionImage"
                        )}
                    <span>
                        {questionComponent && (
                            <span className={"answer "}>
                                {questionComponent.answer}
                            </span>
                        )}
                    </span>
                </CardOutline>
            </div>
        </div>
    );
});

export default withCookies(QuestionCard);
