import React, { useEffect, useRef } from "react";
import "mathlive";

function MathLiveKeyboardContainer({
    className = undefined,
    visible = true,
    dynamicHeight = false
}) {
    const keyboardRef = useRef(null);
    useEffect(() => {
        if (!visible || !window?.mathVirtualKeyboard || !keyboardRef?.current) {
            return;
        }

        window.mathVirtualKeyboard.container = keyboardRef.current;

        const removeShiftCaps = (kbd) =>
            typeof kbd === "string"
                ? kbd
                : {
                      ...kbd,
                      rows: kbd?.rows.map((row) =>
                          row.map((k) =>
                              (k?.insert ||
                                  k?.command ||
                                  k?.latex ||
                                  k?.label) &&
                              k?.label !== "[shift]"
                                  ? { shift: k?.shift ?? { label: "" }, ...k }
                                  : k
                          )
                      )
                  };

        const arrows = [
            {
                class: "action",
                label: "&larr;",
                command: "performWithFeedback(moveToPreviousChar)"
            },
            {
                class: "action",
                label: "&rarr;",
                command: "performWithFeedback(moveToNextChar)"
            }
        ];

        const defaultKeyboard = {
            label: "123",
            rows: [
                [
                    { latex: "x" },
                    { latex: "n" },
                    "[separator-5]",
                    { label: "7", latex: "7" },
                    { label: "8", latex: "8" },
                    { label: "9", latex: "9" },
                    { label: "&divide;", latex: "\\div" },
                    "[separator-5]",
                    { latex: "\\frac{#?}{#?}", class: "small" },
                    { latex: "#@^2}" },
                    { latex: "#@^{#0}}" }
                ],
                [
                    {
                        label: "<",
                        latex: "<",
                        shift: { latex: "\\le", label: "≤" }
                    },
                    {
                        label: ">",
                        latex: ">",
                        shift: { latex: "\\ge", label: "≥" }
                    },
                    "[separator-5]",
                    { label: "4", latex: "4" },
                    { label: "5", latex: "5" },
                    { label: "6", latex: "6" },
                    { label: "&times;", latex: "\\times" },
                    "[separator-5]",
                    { latex: "#?\\frac{#?}{#?}", class: "small" },
                    { latex: "\\sqrt{#0}" },
                    { latex: "\\sqrt[#0]{#?}}" }
                ],
                [
                    { latex: "(", shift: { latex: "[" } },
                    { latex: ")", shift: { latex: "]" } },
                    "[separator-5]",
                    { label: "1", latex: "1" },
                    { label: "2", latex: "2" },
                    { label: "3", latex: "3" },
                    "[-]",
                    "[separator-5]",
                    { label: "&pi;", latex: "\\pi" },
                    { label: "&theta;", latex: "\\theta" },
                    { label: "[backspace]", width: 1.0 }
                ],
                [
                    { label: "[shift]", width: 2.0 },
                    "[separator-5]",
                    { label: "0", latex: "0" },
                    {
                        label: ".",
                        command: "performWithFeedback(insertDecimalSeparator)"
                    },
                    "[=]",
                    { label: "+", latex: "+" },
                    "[separator-5]",
                    ...arrows,
                    {
                        class: "action",
                        command: "performWithFeedback(commit)",
                        width: 1.0,
                        label: "<svg class=svg-glyph><use xlink:href=#svg-commit /></svg>"
                    }
                ]
            ]
        };

        const symbolsKeyboard = {
            label: "+=\\",
            rows: [
                [
                    { latex: "\\sin" },
                    {
                        insert: "\\sin^{-1}\\left(#@\\right)",
                        latex: "\\sin^{-1}"
                    },
                    "[separator-5]",
                    { label: "&plusmn;", latex: "\\pm" },
                    { label: "&ne;", latex: "\\ne" },
                    {
                        label: "&asymp;",
                        latex: "\\approx",
                        shift: { label: "~", latex: "\\sim" }
                    },
                    { label: "&equiv;", latex: "\\equiv" },
                    "[separator-5]",
                    { latex: "\\therefore" },
                    { latex: "\\Rightarrow" },
                    { latex: "\\Leftrightarrow" }
                ],
                [
                    { latex: "\\cos" },
                    {
                        insert: "\\cos^{-1}\\left(#@\\right)",
                        latex: "\\cos^{-1}"
                    },
                    "[separator-5]",
                    { latex: "{#@}^\\circ" },
                    { label: "&ang;", latex: "\\angle" },
                    { label: "&prop;", latex: "\\propto" },
                    { label: "&infin;", latex: "\\infty" },
                    "[separator-5]",
                    {
                        insert: "\\begin{pmatrix} {#?} \\\\ {#?} \\end{pmatrix}",
                        class: "small"
                    },
                    { latex: "\\overrightarrow{#@}" },
                    { latex: "\\dot{#@}", shift: { latex: "\\overline{#@}" } }
                ],
                [
                    { latex: "\\tan" },
                    {
                        insert: "\\tan^{-1}\\left(#@\\right)",
                        latex: "\\tan^{-1}"
                    },
                    "[separator-5]",
                    { label: "&cup;", latex: "\\cup" },
                    { label: "&cap;", latex: "\\cap" },
                    { label: "&isin;", latex: "\\in" },
                    { label: "&ni;", latex: "\\notin" },
                    "[separator-5]",
                    { label: "&pi;", latex: "\\pi" },
                    { label: "&theta;", latex: "\\theta" },
                    { label: "[backspace]", width: 1.0 }
                ],
                [
                    { label: "[shift]", width: 2.0 },
                    "[separator-5]",
                    { latex: "\\colon" },
                    { latex: "\\left\\vert#0\\right\\vert" },
                    { latex: "!" },
                    {
                        input: "\\sum_{#?}^{#?}",
                        latex: "\\textstyle \\sum_{#?}^{#?}",
                        class: "tex"
                    },
                    "[separator-5]",
                    ...arrows,
                    {
                        class: "action",
                        command: "performWithFeedback(commit)",
                        width: 1.0,
                        label: "<svg class=svg-glyph><use xlink:href=#svg-commit /></svg>"
                    }
                ]
            ]
        };

        let abcRows = [];
        for (const templateRow of ["qwertyuiop", " asdfghjkl ", "^zxcvbnm~"]) {
            const row = [];
            for (const k of templateRow) {
                if (/[a-z]/.test(k)) {
                    row.push({
                        label: k,
                        class: "hide-shift",
                        shift: {
                            label: k.toUpperCase()
                        }
                    });
                } else if (k === "~") {
                    row.push({ label: "[backspace]", width: 1.0 });
                } else if (k === "^") row.push("[shift]");
                else if (k === " ") row.push("[separator-5]");
            }
            abcRows.push(row);
        }

        const letterKeyboard = {
            label: "abc",
            rows: [
                ...abcRows,
                [
                    { latex: "-" },
                    { latex: "+" },
                    "[=]",
                    { label: " ", width: 1.5 },
                    {
                        label: ",",
                        shift: ";",
                        variants: ".",
                        class: "hide-shift"
                    },
                    "[.]",
                    ...arrows,
                    { label: "[action]", width: 1.5 }
                ]
            ]
        };

        window.mathVirtualKeyboard.layouts = [
            defaultKeyboard,
            symbolsKeyboard,
            letterKeyboard
        ].map(removeShiftCaps);

        const handleGeometryChange = () => {
            if (dynamicHeight) {
                keyboardRef.current.style.height =
                    window.mathVirtualKeyboard.boundingRect.height + "px";
            }
        };
        window.mathVirtualKeyboard.addEventListener(
            "geometrychange",
            handleGeometryChange
        );

        return () => {
            window.mathVirtualKeyboard.container = document.body;
            window.mathVirtualKeyboard.removeEventListener(
                "geometrychange",
                handleGeometryChange
            );
        };
    }, [dynamicHeight, visible]);

    return <div className={className} ref={keyboardRef} />;
}

export default MathLiveKeyboardContainer;
