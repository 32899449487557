import React, { useContext, useEffect, useMemo, useState } from "react";
import { Badge, Button, Cascader, Popover, Spin, Tag } from "antd";
import {
    filterCascader,
    getImage,
    questionChoiceAsArray,
    questionChoiceAsObject,
    renderCascader,
    TexComponent
} from "helpers/questionHelpers";
import { apiGet } from "helpers/api";
import { getStepIndex, TourContext } from "features/tour/Tour";
import { useGetTopicsTreeQuery } from "features/lesson/topicsSlice";
import { SearchOutlined } from "@ant-design/icons";

const NewBadge = ({ showText = false, isVisible = true }) => {
    if (!isVisible) {
        return null;
    }
    if (showText) {
        return (
            <Tag color="#40EEA4" className="topicNewTag">
                New!
            </Tag>
        );
    }
    return <Badge color="#40EEA4" className="topicNewDot" />;
};

function TopicSelector({
    questionNum,
    isStarter,
    initialTopicChoice,
    setQuestionTopicChoices,
    setStaleEdits,
    clearQuestionComponent,
    cardRef = undefined,
    placement = "bottomLeft"
}) {
    const tourContext = useContext(TourContext);
    const { data: topics, error } = useGetTopicsTreeQuery();
    const shouldPopupBeVisibleForTour = useMemo(
        () =>
            tourContext.isOpen &&
            isStarter &&
            questionNum === 1 &&
            (tourContext.currentStep ===
                getStepIndex("firstQuestionTopicSelect") ||
                tourContext.currentStep ===
                    getStepIndex("firstQuestionTopicHover")),
        [isStarter, questionNum, tourContext.currentStep, tourContext.isOpen]
    );

    const [loadingPreview, setLoadingPreview] = useState(false);
    const [previewTopics, setPreviewTopics] = useState(null);
    const [previewComponent, setPreviewComponent] = useState(null);
    const [popupVisible, setPopupVisible] = useState(
        shouldPopupBeVisibleForTour
    );
    const [isSearching, setIsSearching] = useState(false);
    const [selected, setSelected] = useState(
        questionChoiceAsArray(initialTopicChoice)
    );

    useEffect(() => {
        if (shouldPopupBeVisibleForTour) {
            setPopupVisible(true);
        }
    }, [shouldPopupBeVisibleForTour]);

    useEffect(() => {
        if (!loadingPreview || !previewTopics) {
            return;
        }
        let isMounted = true;
        apiGet(
            `/api/resolved-question?subtopic=${encodeURIComponent(
                previewTopics.subTopic
            )}&difficulty=${encodeURIComponent(
                previewTopics.difficulty
            )}&questionSeed=0&valueSeed=0`,
            (body) => {
                if (isMounted) {
                    body.question = <TexComponent tex={body.question} />;
                    setPreviewComponent(body);
                    setLoadingPreview(false);
                }
            }
        );
        return () => {
            isMounted = false;
        };
    }, [previewTopics, loadingPreview]);

    function onChange(value) {
        setIsSearching(false);

        const choice = questionChoiceAsObject(value);

        if (!choice) {
            clearQuestionComponent();
        }
        if (choice?.difficulty || !choice) {
            setQuestionTopicChoices((prevState) => {
                const copy = [...prevState];
                copy[questionNum - 1] = choice;
                if (!choice) {
                    copy[questionNum - 1] = {};
                }
                if (prevState[questionNum - 1]?.recallTag) {
                    copy[questionNum - 1].recallTag =
                        prevState[questionNum - 1].recallTag;
                }
                copy[questionNum - 1].randomSeed = Math.floor(
                    Math.random() * 10000
                );
                if (choice && cardRef) {
                    cardRef.resetFontSize();
                }
                return copy;
            });
            setStaleEdits(true);
        } else {
            setSelected(value);
        }
        if (
            tourContext.isOpen &&
            ((tourContext.currentStep ===
                getStepIndex("firstQuestionTopicSelect") &&
                choice?.difficulty) ||
                (tourContext.currentStep ===
                    getStepIndex("secondQuestionTopicSelect") &&
                    choice?.difficulty))
        ) {
            setPopupVisible(false);
            tourContext.nextStep();
        }
        // else if (
        //     tourContext.isOpen &&
        //     tourContext.currentStep ===
        //         getStepIndex("firstQuestionTopicSelect") &&
        //     choice?.subtopic
        // ) {
        //     tourContext.nextStep();
        // }
    }

    return (
        <Cascader
            disabled={
                tourContext.isOpen &&
                tourContext.currentStep === getStepIndex("firstQuestion")
            }
            getPopupContainer={() => document.getElementById("app")}
            popupClassName={"topicsPopup"}
            placement={placement}
            options={(!error
                ? topics
                : [
                      {
                          value: "error",
                          label: "Error: Unable to retrieve topics",
                          disabled: true
                      }
                  ]
            )?.map((topicArea) => {
                const taCopy = Object.assign({}, topicArea);
                taCopy.children = taCopy?.children.map((topic) => {
                    const tCopy = Object.assign({}, topic);
                    tCopy.label = (
                        <>
                            {tCopy.label}
                            <NewBadge
                                isVisible={tCopy?.children?.some(
                                    ({ children }) =>
                                        (children || [])?.some(
                                            ({ isNew = false }) => isNew
                                        )
                                )}
                            />
                        </>
                    );
                    tCopy.children = tCopy?.children.map((subTopic) => {
                        const stCopy = Object.assign({}, subTopic);
                        stCopy.label = (
                            <>
                                {stCopy.label}
                                <NewBadge
                                    isVisible={stCopy?.children?.some(
                                        ({ isNew = false }) => isNew
                                    )}
                                />
                            </>
                        );
                        stCopy.children = stCopy?.children
                            .filter((diff) => !diff.disabled)
                            .map((difficulty) => {
                                const copy = Object.assign({}, difficulty);
                                copy.label = (
                                    <Popover
                                        content={
                                            loadingPreview ||
                                            !previewComponent ? (
                                                <Spin tip={"Loading Preview"} />
                                            ) : (
                                                <span
                                                    className={
                                                        "questionPreviewPopover"
                                                    }
                                                >
                                                    {previewComponent.imageUrlSuffix &&
                                                        getImage(
                                                            previewComponent.imageUrlSuffix,
                                                            "questionImage"
                                                        )}
                                                    {previewComponent.question}
                                                </span>
                                            )
                                        }
                                        trigger={"hover"}
                                        {...(difficulty?.disabled
                                            ? { open: false }
                                            : {})}
                                        placement={"right"}
                                        getPopupContainer={() =>
                                            document.getElementById("app")
                                        }
                                        onOpenChange={(visible) => {
                                            setPreviewComponent(null);
                                            if (visible) {
                                                setLoadingPreview(true);
                                                setPreviewTopics({
                                                    subTopic: subTopic.value,
                                                    difficulty: difficulty.value
                                                });
                                            } else {
                                                setPreviewTopics(null);
                                            }
                                        }}
                                        overlayStyle={{ zIndex: 2000 }}
                                        className={"difficultySpan"}
                                    >
                                        {difficulty.label}
                                        {difficulty?.isNew ? (
                                            <NewBadge
                                                showText
                                                isVisible={difficulty?.isNew}
                                            />
                                        ) : (
                                            <Button type={"text"} ghost>
                                                {
                                                    " " /* This blank button seems to make hover trigger more reliable -- might be something to do with the React child component forcing a hover state check or similar */
                                                }
                                            </Button>
                                        )}
                                    </Popover>
                                );
                                return copy;
                            });
                        return stCopy;
                    });
                    return tCopy;
                });
                return taCopy;
            })}
            open={popupVisible}
            onDropdownVisibleChange={(visible) => {
                if (tourContext.isOpen) {
                    if (
                        tourContext.currentStep ===
                        getStepIndex("firstQuestionTopicOpen")
                    ) {
                        tourContext.nextStep();
                    }

                    if (
                        tourContext.currentStep ===
                            getStepIndex("firstQuestionTopicOpen") ||
                        tourContext.currentStep ===
                            getStepIndex("secondQuestionTopicSearch") ||
                        tourContext.currentStep ===
                            getStepIndex("questionExperimenting")
                    ) {
                        setPopupVisible(visible);
                    }
                } else {
                    if (!isSearching) {
                        setPopupVisible(visible);
                    }
                }
            }}
            changeOnSelect
            onChange={onChange}
            placeholder={
                <>
                    <SearchOutlined /> Search or Select Topic
                </>
            }
            showSearch={{
                filter: (inputValue, path) => {
                    setIsSearching(true);
                    if (
                        tourContext.isOpen &&
                        tourContext.currentStep ===
                            getStepIndex("secondQuestionTopicSearch")
                    ) {
                        tourContext.setStep(
                            getStepIndex("secondQuestionTopicSelect")
                        );
                    }
                    return filterCascader(inputValue, path);
                },
                render: renderCascader
            }}
            className="topicSelector"
            data-tour={
                isStarter && questionNum === 1
                    ? "q1TopicSelector"
                    : isStarter && questionNum === 2
                      ? "q2TopicSelector"
                      : undefined
            }
            value={selected?.filter((p) => p)}
        />
    );
}

export default TopicSelector;
