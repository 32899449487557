import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from "react";
import CustomQuestionTextArea from "./CustomQuestionTextArea";
import {
    blocksToString,
    DEFAULT_BLOCKS_VALUE,
    parseValueString
} from "helpers/customQuestionHelpers";
import { Button, Popconfirm, Tooltip } from "antd";
import SvgIconDelete from "assets/icons/IconDelete";
import Icon from "@ant-design/icons";
import { TexComponent } from "helpers/questionHelpers";
import { tooltipColour } from "helpers/starterHelpers";

/**
 * Note: initialValue is not controlled due to differences between cell (block) state and string state (e.g.
 *       creating/deleting empty cells). Edits to initialValue will not be reflected in card state
 */
const CustomQuestionCard = forwardRef(
    (
        {
            title,
            initialValue,
            onChange,
            showPreview = false,
            focusedBlock = null,
            setFocusedBlock = (i) => {}
        },
        ref
    ) => {
        const [values, setValues] = useState(
            initialValue ? parseValueString(initialValue) : DEFAULT_BLOCKS_VALUE
        );
        const [strValue, setStrValue] = useState(initialValue);

        const replaceActiveBlock = useCallback(
            (newBlocks, advanceFocus = true) => {
                if (focusedBlock === null) {
                    return;
                }
                const i = focusedBlock;
                setValues((prev) => [
                    ...prev.slice(0, i),
                    ...newBlocks,
                    ...prev.slice(i + 1)
                ]);
                if (advanceFocus) {
                    setFocusedBlock(focusedBlock + 1);
                }
            },
            [focusedBlock, setFocusedBlock]
        );
        useImperativeHandle(ref, () => ({
            replaceActiveBlock
        }));

        useEffect(() => {
            const str = blocksToString(values);
            setStrValue(str);
            onChange(str);
        }, [onChange, values]);

        return (
            <div className={`custom-question-modal__card`}>
                <div className="custom-question-modal__card__header">
                    <h1>{title}</h1>
                    <div
                        className={"custom-question-modal__card__header-right"}
                    >
                        <Popconfirm
                            title={<>Are you sure?</>}
                            onConfirm={() => setValues(DEFAULT_BLOCKS_VALUE)}
                            onCancel={() => {}}
                            okText="Confirm"
                            cancelText="Cancel"
                        >
                            <Tooltip
                                placement="left"
                                title={"Clear All"}
                                color={tooltipColour}
                            >
                                <Button
                                    type={"text"}
                                    icon={
                                        <Icon
                                            component={() => <SvgIconDelete />}
                                        />
                                    }
                                />
                            </Tooltip>
                        </Popconfirm>
                    </div>
                </div>
                <div className={"custom-question-modal__card__question "}>
                    {showPreview ? (
                        <span>
                            <TexComponent tex={strValue} />
                        </span>
                    ) : (
                        <CustomQuestionTextArea
                            values={values}
                            setValues={setValues}
                            focusedBlock={focusedBlock}
                            setFocusedBlock={setFocusedBlock}
                        />
                    )}
                </div>
            </div>
        );
    }
);

export default CustomQuestionCard;
