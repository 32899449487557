import React, { useCallback, useContext, useMemo, useState } from "react";
import customPlenaryIconColour from "assets/icons/CustomPlenaryIconColour.svg";
import subtopicStarterIconColour from "assets/icons/SubtopicStarterIconColour.svg";
import OurLibraryModal from "features/ourLibrary/OurLibraryModal";
import SaveDetailsForm from "./SaveDetailsForm";
import {
    useGetSavedStartersQuery,
    useNewCustomStarterMutation
} from "features/timetable/savesSlice";
import { createSelector } from "@reduxjs/toolkit";
import { getStarterDefaults } from "helpers/miscHelpers";
import { useHistory, useLocation } from "react-router-dom";
import { useGetUserQuery } from "features/account/accountSlice";
import BaseLessonTypeModal from "./BaseLessonTypeModal";
import LessonTypeOption from "features/lesson/LessonTypeOption";
import LessonContext from "../../context/LessonContext";

function SelectPlenaryTypeModal({
    open,
    setOpen,
    saveId = null,
    createLibraryPlenary
}) {
    const history = useHistory();
    const location = useLocation();

    const { isSaveable } = useContext(LessonContext);
    const { data: user } = useGetUserQuery();
    const [newCustomStarter] = useNewCustomStarterMutation();

    const [showNewLibraryPlenary, setShowNewLibraryPlenary] = useState(false);

    const selectSaveForId = useMemo(() => {
        return createSelector(
            (res) => res.data,
            (res, saveId) => saveId,
            (savesTable, saveId) =>
                saveId &&
                Object.values(savesTable || {})
                    ?.flat()
                    ?.filter((s) => s)
                    ?.find((s) => s.id === saveId)
        );
    }, []);
    const { save: saveDetails } = useGetSavedStartersQuery(undefined, {
        selectFromResult: (result) => ({
            save: selectSaveForId(result, saveId)
        })
    });

    const newStarterModals = (
        <>
            <OurLibraryModal
                isVisible={showNewLibraryPlenary}
                setIsVisible={setShowNewLibraryPlenary}
                onSelect={({ subTopic, difficulty }) => {
                    createLibraryPlenary({ subTopic, difficulty });
                    setOpen(false);
                }}
            />
        </>
    );

    const handleNewSaveFromForm = useCallback(
        ({ name, classId }) => {
            newCustomStarter({
                ...getStarterDefaults(user),
                name: name,
                classId: classId,
                timetableColumn: "backlog"
            })
                .unwrap()
                .then((res) => {
                    history.replace({
                        pathname: location.pathname,
                        search: `?id=${res.id}`
                    });
                });
        },
        [history, location.pathname, newCustomStarter, user]
    );

    return (
        <>
            {newStarterModals}
            <BaseLessonTypeModal
                className={"plenary"}
                open={open}
                setOpen={setOpen}
                title={"Select Exit Ticket Template"}
            >
                <LessonTypeOption
                    name={"Custom Exit Ticket"}
                    description={
                        "Select the topics you want for each question."
                    }
                    colour={"green"}
                    icon={customPlenaryIconColour}
                    onClick={() => setOpen(false)}
                />
                <LessonTypeOption
                    name={"Subtopic Exit Ticket"}
                    description={
                        "Browse and select from our differentiated subtopic library."
                    }
                    colour={"pink"}
                    icon={subtopicStarterIconColour}
                    onClick={() => setShowNewLibraryPlenary(true)}
                />
                {isSaveable && (
                    <SaveDetailsForm
                        saveId={saveId}
                        saveDetails={saveDetails}
                        isDropdown
                        onSaveNew={handleNewSaveFromForm}
                    />
                )}
            </BaseLessonTypeModal>
        </>
    );
}

export default SelectPlenaryTypeModal;
