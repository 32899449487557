import * as React from "react";

const SvgIconTab = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={"2em"}
        height={"1em"}
        viewbox={"0 1 24 12"}
        fill="none"
        {...props}
    >
        <path
            fill="#3E3E3E"
            d="M1 5.96a.8.8 0 1 0 0 1.6v-1.6Zm17.709 1.366a.8.8 0 0 0 0-1.132l-5.092-5.09a.8.8 0 0 0-1.131 1.13l4.526 4.526-4.526 4.526a.8.8 0 0 0 1.131 1.13l5.091-5.09ZM21.8 1a.8.8 0 0 0-1.6 0h1.6Zm-1.6 12a.8.8 0 0 0 1.6 0h-1.6ZM1 7.56h17.143v-1.6H1v1.6ZM20.2 1v12h1.6V1h-1.6Z"
        />
    </svg>
);
export default SvgIconTab;
