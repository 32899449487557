import React, { useContext, useEffect, useState } from "react";
import AccountInfo from "./components/AccountInfo";
import SubscriptionInfo from "./components/SubscriptionInfo";
import SubUsersForm from "./components/SubUsersForm";
import { Card, Divider, Tabs } from "antd";
import Title from "antd/lib/typography/Title";
import PreferencesForm from "./components/PreferencesForm";
import Icon, {
    AppstoreAddOutlined,
    TeamOutlined,
    UserOutlined
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { getStepIndex, TourContext } from "features/tour/Tour";
import {
    getSubscriptionType,
    isEnvironmentContentCreationOnly,
    isUserSiteAdmin
} from "helpers/security";
import SvgUserMetricsIcon from "assets/icons/UserMetricsIcon";
import UserMetricsPage from "features/accountsAdmin/UserMetricsPage";
import { useGetUserQuery } from "features/account/accountSlice";

function AccountPage() {
    const { data: user } = useGetUserQuery();
    const { pageKey } = useParams();

    const [activeTab, setActiveTab] = useState(pageKey);

    useEffect(() => setActiveTab(pageKey), [pageKey]);

    const tourContext = useContext(TourContext);

    const loggedIn = Boolean(user);
    const isSiteAdmin = isUserSiteAdmin(user);
    const isNotSubUser = getSubscriptionType(user) !== "SubSite";

    const history = useHistory();

    const isContentEnvironment = isEnvironmentContentCreationOnly();

    if (isContentEnvironment) {
        return (
            <div
                style={{
                    width: "fit-content",
                    "margin-left": "auto",
                    "margin-right": "auto"
                }}
            >
                <AccountInfo />
            </div>
        );
    }

    return (
        <Tabs
            size={"large"}
            className={"accountInfoContainer"}
            activeKey={activeTab}
            onChange={(activeTab) => {
                if (tourContext.isOpen) {
                    if (
                        tourContext.currentStep === getStepIndex("subUsersOpen")
                    ) {
                        tourContext.nextStep();
                        setActiveTab("sub-users");
                    }
                } else {
                    history.replace("/settings/" + activeTab);
                    setActiveTab(activeTab);
                }
            }}
        >
            <Tabs.TabPane
                tab={
                    <span>
                        <UserOutlined />
                        {isNotSubUser ? "Account / Billing" : "Account"}
                    </span>
                }
                key={"account"}
            >
                <div className={"accountCardsContainer"}>
                    <AccountInfo />
                    {loggedIn && isNotSubUser && <SubscriptionInfo />}
                    {/*<ReferralInfo />*/}
                </div>
            </Tabs.TabPane>
            {loggedIn && (
                <Tabs.TabPane
                    tab={
                        <span>
                            <AppstoreAddOutlined />
                            Template Defaults
                        </span>
                    }
                    key={"starter"}
                >
                    <Card className={"card-large"}>
                        <Title level={2}>Default Template Settings</Title>
                        <Divider />
                        <PreferencesForm />
                    </Card>
                </Tabs.TabPane>
            )}
            {loggedIn && isSiteAdmin && (
                <Tabs.TabPane
                    tab={
                        <span data-tour={"settingsSubUsersButton"}>
                            <TeamOutlined />
                            Department Members
                        </span>
                    }
                    key={"sub-users"}
                >
                    <Card
                        className={"card-large"}
                        data-tour={"settingsSubUsers"}
                    >
                        <Title level={2}>Department Members</Title>
                        <Divider />
                        <SubUsersForm />
                    </Card>
                </Tabs.TabPane>
            )}
            {loggedIn && isSiteAdmin && (
                <Tabs.TabPane
                    tab={
                        <span data-tour={"settingsMetricsButton"}>
                            <Icon component={() => <SvgUserMetricsIcon />} />
                            Department Metrics
                        </span>
                    }
                    key={"metrics"}
                >
                    <Card
                        className={"card-large"}
                        data-tour={"settingsMetrics"}
                    >
                        <UserMetricsPage />
                    </Card>
                </Tabs.TabPane>
            )}
        </Tabs>
    );
}

export default AccountPage;
